import React from "react";
import { Routes } from "./Constants";
import "./styles/PageFooter.scss";
import * as Icons from "../_assets/2d/Icons";
import { Link } from "@reach/router";
import { StateStore } from "../utils";

export default () => {
  const type = "{type}";

  const selectLocation = (location) => {
    setTimeout(() => StateStore.publish("location-details", location), 100);
  };

  return (
    <footer>
      <div>
        <div className="copyright">
          <Icons.LogoIcon />
          Copyright {type}DEV 2014 - {new Date().getFullYear()}. All rights
          reserved.
        </div>
        <div className="site-map">
          <div className="nav-link-wrapper">
            <Link to={Routes.Home}>Home</Link>
          </div>
          <div className="nav-link-wrapper">
            <Link to={Routes.Team}>Team Up</Link>/
            <Link to={`${Routes.Team}${Routes.More}`}>Read More</Link>
          </div>
          <div className="nav-link-wrapper">
            <Link to={Routes.Architect}>Architect a Lasting Solution</Link>/
            <Link to={`${Routes.Architect}${Routes.More}`}>Read More</Link>
          </div>
          <div className="nav-link-wrapper">
            <Link to={Routes.Ship}>Ship Quality Software</Link>/
            <Link to={`${Routes.Ship}${Routes.More}`}>Read More</Link>
          </div>
          <div className="nav-link-wrapper">
            <Link to={Routes.Experience}>
              Experience Something Breathtaking
            </Link>
            /<Link to={`${Routes.Experience}${Routes.More}`}>Read More</Link>
          </div>
        </div>
        <div className="contact-details">
          <div className="contact-links">
            <h3>Get in contact with us</h3>
            <Link
              to={Routes.Contact}
              onClick={() => selectLocation("Centurion")}
            >
              <Icons.SouthAfricanFlagIcon />
              Centurion
            </Link>
            <Link
              to={Routes.Contact}
              onClick={() => selectLocation("Cape Town")}
            >
              <Icons.SouthAfricanFlagIcon />
              Cape Town
            </Link>
            <Link to={Routes.Contact} onClick={() => selectLocation("Vienna")}>
              <Icons.AustrianFlagIcon />
              Vienna
            </Link>
          </div>
          <div className="social-links">
            <h3>Follow us on social media</h3>
            <a
              href="https://www.facebook.com/typedev/"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons.FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/typedev_/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons.InstagramIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/typedev/posts/"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons.LinkedInIcon />
            </a>
            <a
              href="https://www.youtube.com/@typedevsa"
              title="Youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons.YoutubeIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
