import React, { useState } from "react";
import { Scene } from "./3d";
import "./styles/QualitySwitcher.scss";

export default (props) => {
  const [quality, setQuality] = useState("auto");

  const handleClick = (q) => {
    setQuality(q);
    Scene().setQuality(q);
  };

  return (
    <div {...props} className="quality-switcher">
      <h2>Quality</h2>
      <span
        className={`low${quality === "low" ? " selected" : ""}`}
        onClick={() => handleClick("low")}
      >
        Low
      </span>
      <span
        className={`auto${quality === "auto" ? " selected" : ""}`}
        onClick={() => handleClick("auto")}
      >
        Auto
      </span>
      <span
        className={`high${quality === "high" ? " selected" : ""}`}
        onClick={() => handleClick("high")}
      >
        High
      </span>
    </div>
  );
};
