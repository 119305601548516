import React from "react";
import "./styles/DetailHeading.scss";

/**
 * @param {string} main
 * @param {string} rest
 */
export default (props) => {
  const { main, rest } = props;

  return (
    <h1 className="detail-heading">
      <em>{main}</em>
      <span>{rest}</span>
    </h1>
  );
};
