import React from "react";

export default (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 53.225193 33.035191"
  >
    <g id="layer1" transform="translate(-20.67482,-16.410251)">
      <path
        id="rect31"
        style={{
          fill: "#ffffff",
          stopColor: "#000000",
        }}
        d="M 24.273438 0 L 0 24.273438 L 76.308594 100.58203 L 100.58398 124.85742 L 124.85742 100.58398 L 201.16602 24.273438 L 176.89258 0 L 100.58203 76.308594 L 24.273438 0 z "
        transform="matrix(0.26458333,0,0,0.26458333,20.67482,16.410251)"
      />
    </g>
  </svg>
);
