import React from "react";
import "./styles/DetailContent.scss";

/**
 * @param {string} children
 */
export default (props) => {
  const { children } = props;

  return (
    <div className="detail-content">
      <div>{children}</div>
    </div>
  );
};
