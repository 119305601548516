const store = {};
const hub = {};
const allowLocalStorage = false;
const logErrors = false;
const topicToDebug = null;

class _StateStore {
  subscribe = (topic, context, eventHandler) => {
    if (topic instanceof Function) topic = topic();

    if (!hub[topic]) hub[topic] = [];

    if (topic === topicToDebug) console.log(`subscribe: ${topic}`, context);

    hub[topic].push({ context, eventHandler });
  };

  unsubscribe = (topic, context) => {
    if (topic instanceof Function) topic = topic();

    if (!hub[topic]) return;

    if (topic === topicToDebug) console.log(`unsubscribe: ${topic}`, context);

    hub[topic].forEach((c, i) => {
      if (c.context === context) hub[topic].splice(i, 1);
    });

    if (!hub[topic].length) delete hub[topic];
  };

  publish(args) {
    args =
      arguments && arguments.length === 1
        ? args
        : {
            topic: arguments[0],
            message: arguments[1],
            updateStore: arguments[2],
          };
    let { topic, message, updateStore } = args;

    if (topic instanceof Function) topic = topic();

    if (topic === topicToDebug)
      console.log(`publish: ${topic}`, JSON.parse(JSON.stringify(message)));

    if (updateStore === null || updateStore === undefined) updateStore = true;

    if (updateStore) this.store(topic, message);

    if (hub[topic])
      hub[topic].map((c) => {
        let { context, eventHandler } = c;

        if (eventHandler) {
          if (context.render) eventHandler(context, message);
          if (!context || !context.render) eventHandler(message);
          return c;
        }

        let toSet = {};
        toSet[topic] = message;
        context.setState && context.setState(toSet);

        return c;
      });
  }

  unset = (topic) => {
    delete store[topic];
  };

  store = (topic, message) => {
    if (topic instanceof Function) topic = topic();

    store[topic] = message;

    if (topic === topicToDebug)
      console.log(`store: ${topic}`, JSON.parse(JSON.stringify(message)));

    if (
      allowLocalStorage &&
      message instanceof Object &&
      !message.$$typeof &&
      typeof message !== "function"
    ) {
      try {
        localStorage.setItem(topic, JSON.stringify(message));
      } catch (e) {
        logErrors &&
          console.error(`StateStore.store => ${topic} =>`, e.message);
      }
    }
  };

  retrieve = (topic) => {
    if (topic instanceof Function) topic = topic();

    if (topic === topicToDebug)
      console.log(
        `retrieve: ${topic}`,
        store[topic] ? JSON.parse(JSON.stringify(store[topic])) : "undefined"
      );

    if (store[topic]) return store[topic];

    if (allowLocalStorage) {
      let lsValue = localStorage.getItem(topic);

      if (!lsValue) return null;

      return JSON.parse(lsValue);
    }

    return null;
  };
}

export const StateStore = new _StateStore();
