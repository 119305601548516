import * as THREE from "three";
import * as Partykals from "partykals/partykals";
import Scene from "./Scene";
import { destroyObject } from "../../utils/AssetUtils";
import { getAsset } from "../../utils";

class ParticleEmitter {
  constructor(args) {
    this.args = args;

    this.system = new Partykals.ParticlesSystem({
      container: Scene().scene,
      particles: {
        ttl: args?.ttl ?? 2,
        velocity: new Partykals.Randomizers.SphereRandomizer(0.1),
        velocityBonus: new THREE.Vector3(0, 0.02, 0),
        gravity: args?.gravity ?? -0.05,
        startColor: new THREE.Color(0xffffff),
        endColor: new THREE.Color(0xffffff),
        startAlpha: 1,
        endAlpha: 0,
        startSize: 0,
        endSize: args?.size ?? 0.6,
        offset: new Partykals.Randomizers.SphereRandomizer(2.1, 3),
        rotation: new Partykals.Randomizers.MinMaxRandomizer(1, 5),
        rotationSpeed: new Partykals.Randomizers.MinMaxRandomizer(-3, 3),
        blending: "additive",
        onUpdate: (particle) => {
          const floorY = args?.floorY ?? 0;
          const ceilY = args?.ceilY ?? 0.8;

          args?.onUpdate && args.onUpdate();

          if (particle.position.y < floorY) {
            particle.position.y = floorY;
            particle.velocity.y *= -0.5;
            particle.velocity.x *= 0.5;
            particle.velocity.z *= 0.5;
          }

          if (particle.position.y > ceilY) {
            particle.position.y = ceilY;
            particle.velocity.y *= 0.5;
            particle.velocity.x *= 0.5;
            particle.velocity.z *= 0.5;
          }
        },
        texture: getAsset("Images.WhiteGradient"),
      },
      system: {
        particlesCount: args?.count ?? 5500,
        scale: args?.scale ?? 120,
        emitters: new Partykals.Emitter({
          onInterval: new Partykals.Randomizers.MinMaxRandomizer(30, 50),
          interval: new Partykals.Randomizers.MinMaxRandomizer(0, 0.25),
        }),
        depthWrite: false,
        speed: args?.speed ?? 0.7,
      },
    });
    this.system.particleSystem.name = "dust particles";

    this.object3d = this.system.particleSystem;
  }

  updateFrame = () => {
    this.system.update();
  };

  stop = () => {
    this.object3d.visible = false;
    this.system.options.particles.ttl = 0.0001;
    this.system.options.system.particlesCount = 1;
  };

  start = () => {
    this.object3d.visible = true;
    this.system.options.particles.ttl = this.args?.ttl ?? 2;
    this.system.options.system.particlesCount = this.args?.count ?? 1500;
  };

  destroy() {
    destroyObject(this.object3d);
  }
}

export default (args) => new ParticleEmitter(args);
