import React, { useEffect } from "react";
import * as ExperienceScene from "./ExperienceScene";

export default (props) => {
  const { children } = props;

  useEffect(() => {
    ExperienceScene.create();
    ExperienceScene.transitionIn();

    return ExperienceScene.transitionOut;
  }, []);

  return <>{children}</>;
};
