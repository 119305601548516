import React from "react";
import PageFooter from "./PageFooter";
import "./styles/Page.scss";

export default (props) => {
  const { className, children, footer, ...otherProps } = props;
  let classes = ["page"];

  if (className) classes = classes.concat(className.split(" "));

  return (
    <div {...otherProps} className={classes.join(" ")}>
      {children}
      {footer && <PageFooter size={footer} />}
    </div>
  );
};
