import React, { useEffect, useState } from "react";
import { responsiveHeightSubtraction } from "../utils";
import {
  ClientAul,
  ClientBoredPass,
  ClientCardCurator,
  ClientGenasys,
  ClientInfluapp,
  ClientTypedev,
  Cloud1,
  Cloud2,
  Cloud3,
  Cloud4,
} from "../_assets/2d/Images";
import {
  Page,
  DetailHeading,
  DetailContent,
  ScrollDownIndicator,
  Section,
  TechIcon,
} from "../_common";
import { Scene } from "../_common/3d";
import { CameraPositions, ScenePositions, Scenes } from "../_common/Constants";
import * as ExperienceScene from "./ExperienceScene";
import "./styles/ExperienceDetails.scss";

export default (_props) => {
  const { onMount, screenSize } = _props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
    setTimeout(
      () =>
        setHeight(
          window.innerHeight -
            responsiveHeightSubtraction() -
            (window.innerWidth > 810 ? 85 : 65)
        ),
      1500
    );
  }, []);

  useEffect(() => {
    ExperienceScene.whenReady(() => {
      Scene().moveScenePosition(ScenePositions[screenSize].detail.Experience);
      Scene().moveCameraPosition(CameraPositions[screenSize].detail.Experience);
      onMount && onMount(Scenes.ExperienceMore);
    });
  }, [onMount, screenSize]);

  if (!loaded) return <></>;

  return (
    <Page
      id="experience-details"
      className="details"
      footer="large"
      style={{ marginTop: height }}
    >
      <ScrollDownIndicator />
      <DetailHeading main="Experience" rest="Something Breathtaking" />
      <DetailContent>
        <Section>
          <p>
            As we continuously strive to deliver products of quality, we are
            proud when the products worked on are recognised for their
            outstanding quality. Below are examples of recent awards these
            products received:
          </p>
          <ul>
            <li>
              <em>Card Curator</em>, a US based Fintech application took centre
              stage at the prestigious 2021 Fintech Awards, having been bestowed
              the <em>Best Card Rewards Solution 2021 Award</em> as well as the{" "}
              <em>Consumer Fintech Innovation Excellence Award</em>. These
              awards come off the back of having won the{" "}
              <em>2021 Summer Venture Award</em> from Wharton University for
              maximising rewards program benefits using AI. We developed the
              full stack of their offering.
            </li>
            <li>
              <em>Pineapple</em>, an Insurtech application won{" "}
              <em>MTN 2018’s Consumer App Of The Year</em>. Although the
              application was developed under the Pineapple Tech name, we were
              the key contributor on the project, involved from concept to
              launch of the product. We also established and managed the
              relationship with Brolink to allow them to kickstart their
              offering and quickly enter the market.
            </li>
            <li>
              <em>Ctrl</em>, another Insurtech company based in Cape Town, won{" "}
              <em>MTN 2018’s Best Financial Solution Of The Year</em>. We played
              a crucial role in developing integration layers between the Ctrl
              application and Brolink’s Websure policy administration system.
            </li>
          </ul>
          <p>
            Our Master Software Crafters treat every project and assignment with
            the utmost focus and dedication to ensure the quality of our work
            fosters longstanding relationships with our clients. We live our
            dreams, and invite you to do the same by journeying together to
            realise your grandest project vision. Below are some case studies of
            satisfied clients who have journeyed with us on their own
            breathtaking digital experiences.
          </p>
        </Section>
        <Section
          heading="Case Study: Genasys Technologies RSA"
          graphic={<LogoInClouds logo={ClientGenasys} />}
        >
          <p>
            Genasys is a comprehensive and dynamic insurance administration
            management system that can be applied to any insurance business,
            regardless of currency, language or destination. Genasys
            Technologies needed assistance in designing and developing the
            frontend of their new B2C platform whilst syncing it with their
            existing B2B platform.
          </p>
          <div className="technologies-used">
            <TechIcon small icon="angular" text="Angular" />
            <TechIcon small icon="react" text="React.js" />
          </div>
        </Section>
        <Section
          heading="Case Study: Card Curator"
          graphic={
            <LogoInClouds
              logo={ClientCardCurator}
              style={{ backgroundSize: "98%", top: "20%" }}
            />
          }
        >
          <p>
            Card Curator is a mobile application that helps users track their
            reward programs dedicated to their various credit cards. It assists
            the user to set up goals that they would like to achieve on these
            programs and suggests the best card to use for each purchase to
            reach these goals.
          </p>
          <div className="technologies-used">
            <TechIcon small icon="flutter" text="Flutter" />
            <TechIcon small icon="php" text="PHP" />
            <TechIcon small icon="laravel" text="Laravel" />
            <TechIcon small icon="mongo" text="MongoDB" />
            <TechIcon small icon="javascript" text="JavaScript" />
            <TechIcon small icon="node" text="Node.js" />
            <TechIcon small icon="react" text="React.js" />
          </div>
        </Section>
        <Section
          heading="Case Study: BoredPass"
          graphic={
            <LogoInClouds
              logo={ClientBoredPass}
              style={{ backgroundSize: "90%", top: "8%" }}
            />
          }
        >
          <p>
            BoredPass, a start-up in the recreational tourism sector, contracted
            us to develop their global solution from the ground up. The solution
            is premised on geo-location, coupled with an open-source, user
            generated content model to facilitate international scalability.
          </p>
          <div className="technologies-used">
            <TechIcon small icon="html5" text="HTML5" />
            <TechIcon small icon="css3" text="CSS3" />
            <TechIcon small icon="node" text="Node.js" />
            <TechIcon small icon="javascript" text="JavaScript" />
            <TechIcon small icon="mongo" text="MongoDB" />
          </div>
        </Section>
        <Section
          heading="Case Study: Influapp"
          graphic={
            <LogoInClouds
              logo={ClientInfluapp}
              style={{ backgroundSize: "90%", top: "17%" }}
            />
          }
        >
          <p>
            Influapp is the review-for-reward app that brings together loved
            ones and local businesses. They are the exciting new community where
            you can share what you love with those you love and earn rewards as
            you go. Make and receive contactless easy payment from friends to
            friends, customers to business, wherever you go. By signing up to
            become an Influapp Affiliate, your business will be noticed on the
            feeds and streams of all their Members’ social media and on Influapp
            listings. We were contracted to conduct the strategy analysis,
            market research and UI designs for the application. Development is
            ongoing.
          </p>
        </Section>
        <Section
          heading="Case Study: Our Website"
          graphic={
            <LogoInClouds
              logo={ClientTypedev}
              style={{ backgroundSize: "87%", top: "11%" }}
            />
          }
        >
          <p>
            The global COVID-19 pandemic forced us, much like everyone else, to
            focus on the value that we're adding to the clients and industries
            we serve. We thought about our brand, values and message and decided
            that we wanted to showcase our unique blend of technical proficiency
            and artistic mastery in a portfolio piece. The culmination of
            dreams, workshops and several drafts was our new website. Our goal
            was to leverage modern browser rendering capabilities to provide an
            immersive journey through our colourful world, with specific focus
            on React.js and Three.js.
          </p>
          <div className="technologies-used">
            <TechIcon small icon="react" text="React.js" />
            <TechIcon small icon="threejs" text="Three.js" />
          </div>
        </Section>
      </DetailContent>
    </Page>
  );
};

const LogoInClouds = ({ logo, style }) => (
  <div className="clouds">
    <div className="cloud-1" style={{ backgroundImage: `url(${Cloud1})` }} />
    <div className="cloud-2" style={{ backgroundImage: `url(${Cloud2})` }} />
    <div className="cloud-3" style={{ backgroundImage: `url(${Cloud3})` }} />
    <div className="cloud-4" style={{ backgroundImage: `url(${Cloud4})` }} />
    <div
      className="logo"
      style={{ ...style, backgroundImage: `url(${logo})` }}
    />
  </div>
);
