import { Link } from "@reach/router";
import React from "react";
import "./styles/IconLink.scss";

export default (props) => {
  const { link, icon, text } = props;
  return (
    <Link to={link} className={`icon-link ${props.className}`}>
      {icon}
      {text}
    </Link>
  );
};
