import * as THREE from "three";
import { getAsset } from "../../utils/AssetLoader";
import TransitionableMesh from "../../_common/3d/TransitionableMesh";
import gsap, { Power1 } from "gsap";

class AirshipClass extends TransitionableMesh {
  constructor(args) {
    super(args);

    let { scene } = getAsset("Models.Airship", true);
    const scale = args?.scale / 100 ?? 0.1;

    scene.scale.x = scale;
    scene.scale.y = scale;
    scene.scale.z = scale;
    scene.rotation.x = args?.rotation?.x ?? 0;
    scene.rotation.y = args?.rotation?.y ?? 0;
    scene.rotation.z = args?.rotation?.z ?? 0;
    scene.position.x = args?.position?.x ?? 0;
    scene.position.y = args?.position?.y ?? 0;
    scene.position.z = args?.position?.z ?? 0;

    scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;

        if (/rope/.test(child.material.name)) {
          child.material.transparent = true;
          child.material.color = new THREE.Color(0x000000);
        }
      }
    });

    this.init(scene);
  }

  float = (amount, duration) => {
    if (this.floating) return this;

    const bob = { y: this.object3d.position.y };

    this.bobTween = gsap.to(this.object3d.position, {
      y: bob.y - (amount ?? 0.01),
      duration: duration ?? 1.1,
      repeat: -1,
      yoyo: true,
      ease: Power1.easeInOut,
    });

    this.floating = true;

    return this;
  };

  stop = () => {
    this.bobTween && this.bobTween.kill();
    this.floating = false;
  };

  flyIn = (position, rotation, duration, delay, then) => {
    const ctx = this;
    const ease = Power1.easeOut;

    ctx.hide();

    setTimeout(() => {
      ctx.show();
      gsap.to(this.object3d.position, {
        ...position,
        duration: duration / 1000,
        ease,
      });
      gsap.to(this.object3d.rotation, {
        ...rotation,
        duration: duration / 1000,
        ease,
        onComplete: then,
      });
    }, delay ?? 0);

    return this;
  };
}

export default (args) => new AirshipClass(args);
