import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
//import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass";
//import { SAOPass } from "three/examples/jsm/postprocessing/SAOPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader";
import "../styles/Scene.scss";
import { Plane } from ".";
import { Vector3 } from "three";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import gsap, { Power1 } from "gsap";
import { isMobile, responsiveHeightSubtraction } from "../../utils";

class SceneClass {
  constructor(args) {
    const renderScale = args?.renderScale ?? 1;
    const breakpoints = args?.breakpoints?.map((bp) => bp) ?? [];
    let height = window.innerHeight / renderScale;
    let width = window.innerWidth / renderScale;

    for (let bp of breakpoints) {
      if (window.innerWidth <= bp.maxWidth) {
        height =
          (/\d%/.test(bp.height)
            ? (window.innerHeight * parseFloat(bp.height.replace("%", ""))) /
              100
            : bp.height) / renderScale;

        height -= responsiveHeightSubtraction();
        break;
      }
    }

    // Setup renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    window.renderer = this.renderer;
    this.calculatedPixelRatio = args?.pixelRatio ?? 1.5;
    this.renderer.setPixelRatio(this.calculatedPixelRatio);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setSize(width, height);
    this.renderer.domElement.id = args?.id ?? "three-scene";
    this.renderer.outputEncoding = THREE.LinearEncoding;
    this.renderer.toneMapping = THREE.CineonToneMapping;
    this.renderer.toneMappingExposure = 1.7;

    // Setup camera
    this.camera = new THREE.PerspectiveCamera(
      args?.camera?.fov ?? 50,
      width / height,
      args?.camera?.near ?? 0.1,
      args?.camera?.far ?? 110
    );
    this.cameraTarget = new THREE.Vector3(0, 0, 0);

    this.cameraOrigin = {
      rotation: {
        x: this.camera.rotation.x,
        y: this.camera.rotation.y,
        z: this.camera.rotation.z,
      },
      position: {
        x: this.camera.position.x,
        y: this.camera.position.y,
        z: this.camera.position.z,
      },
    };

    const backgroundColor = args?.backgroundColor ?? new THREE.Color(0x000000);

    // Setup scene
    this.scene = new THREE.Scene();
    this.scene.background = backgroundColor;
    this.scene.fog = new THREE.Fog(backgroundColor, 0.1, 70);

    // Setup post processing
    this.composer = new EffectComposer(this.renderer);

    /*const saoPass = new SAOPass(this.scene, this.camera);
    saoPass.params.saoBias = 15;
    saoPass.params.saoScale = 5.9;
    saoPass.params.saoIntensity = 2;
    saoPass.params.saoKernelRadius = 5;*/

    this.composer.addPass(new RenderPass(this.scene, this.camera));
    //this.composer.addPass(saoPass);
    //this.composer.addPass(new SMAAPass(width, height));
    this.composer.addPass(
      new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        1.1,
        0.5,
        0.859
      )
    );
    this.composer.addPass(new ShaderPass(GammaCorrectionShader));

    // Create group for all actors
    this.actorGroup = new THREE.Group();
    this.scene.add(this.actorGroup);

    this.sceneOrigin = {
      rotation: {
        x: this.actorGroup.rotation.x,
        y: this.actorGroup.rotation.y,
        z: this.actorGroup.rotation.z,
      },
      position: {
        x: this.actorGroup.position.x,
        y: this.actorGroup.position.y,
        z: this.actorGroup.position.z,
      },
    };

    // Init member arrays
    this.actors = [];
    this.resizeHandlers = [
      (w, h) => {
        this.renderer.setSize(w, h);
        this.composer.setSize(w, h);
        this.camera.aspect = w / h;
        this.camera.updateProjectionMatrix();
      },
    ];

    if (args?.onresize) this.resizeHandlers.push(args.onresize);

    // Add plane
    this.plane = Plane({
      material: {
        color: backgroundColor,
      },
      position: { y: -0.9 },
    });
    this.add(this.plane);

    // Create canvas element on the page
    (args?.target ?? document.body).appendChild(this.renderer.domElement);

    window.onresize = () => {
      width = window.innerWidth / renderScale;

      if (!isMobile()) {
        height = window.innerHeight / renderScale;

        for (let bp of breakpoints) {
          if (window.innerWidth <= bp.maxWidth) {
            height =
              (/\d%/.test(bp.height)
                ? (window.innerHeight *
                    parseFloat(bp.height.replace("%", ""))) /
                  100
                : bp.height) / renderScale;

            height -= responsiveHeightSubtraction();
            break;
          }
        }
      }

      this.resizeHandlers.map((h) => h(width, height));
    };

    this.lastFrame = new Date().getTime();

    this.raycaster = new THREE.Raycaster();
    this.mouseCoordinates = new THREE.Vector2();

    document.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("mousemove", this.onMouseMove);
  }

  updateRaycaster = (event) => {
    event.preventDefault();

    this.mouseCoordinates.x =
      (event.clientX / this.renderer.domElement.clientWidth) * 2 - 1;
    this.mouseCoordinates.y =
      -(event.clientY / this.renderer.domElement.clientHeight) * 2 + 1;
    this.raycaster.setFromCamera(this.mouseCoordinates, this.camera);
  };

  onMouseDown = (event) => {
    this.updateRaycaster(event);

    this.actors.forEach((a) => {
      a.getClickableObjects &&
        a.getClickableObjects().forEach((c) => {
          const intersects = this.raycaster.intersectObject(
            c.object3d ?? c,
            true
          );

          if (intersects?.length) c.onClick && c.onClick();
        });
    });
  };

  onMouseMove = (event) => {
    this.updateRaycaster(event);

    this.actors.forEach((a) => {
      if (!a.getClickableObjects) return;

      for (var c of a.getClickableObjects()) {
        const mesh = c.object3d ?? c;
        const intersects = this.raycaster.intersectObject(mesh, true);

        if (intersects?.length) {
          this.currentHover = { mesh: intersects[0], object: c };
          c.onMouseOver && c.onMouseOver();
          return;
        }

        if (this.currentHover?.object) {
          this.currentHover.object.onMouseOut &&
            this.currentHover.object.onMouseOut();
          this.currentHover = null;
        }
      }
    });
  };

  setQuality = (quality) => {
    switch (quality) {
      case "high":
        this.renderer.setPixelRatio(2);
        break;
      case "low":
        this.renderer.setPixelRatio(0.7);
        break;
      default:
        this.renderer.setPixelRatio(this.calculatedPixelRatio);
    }
  };

  /**
   * Adds an actor to the scene.
   * @param {Object3D} actor The actor to add.
   */
  add = (actor) => {
    this.actors.push(actor);
    actor.object3d && this.actorGroup.add(actor.object3d);
    actor.target && this.actorGroup.add(actor.target);

    return this;
  };

  /**
   * Removes an actor from the scene.
   * @param {Object3D} actor The actor to remove.
   */
  remove = (actor) => {
    this.actors.splice(
      this.actors.findIndex((a) => a.object3d.uuid === actor.object3d.uuid),
      1
    );
    actor.object3d && this.actorGroup.remove(actor.object3d);

    return this;
  };

  /**
   * Gets an actor in the scene by the provided selector.
   * @param {Function} selector A function to execute on each actor to return it.
   */
  getActor = (selector) => {
    const matches = this.actors.filter(selector);

    return matches.length ? matches[0] : null;
  };

  /**
   * Animates all actors and objects in the scene.
   */
  animate = () => {
    requestAnimationFrame(this.animate.bind(this));

    window.actors = this.actors;
    this.actors.map((a) => a.animate && a.animate());

    // this.renderer.render(this.scene, this.camera);
    this.composer.render();

    return this;
  };

  /**
   * @param {THREE.Vector3} position
   */
  setCameraPosition = (position) => {
    this.camera.position.x = position?.x ?? this.camera.position.x ?? 0;
    this.camera.position.y = position?.y ?? this.camera.position.y ?? 0;
    this.camera.position.z = position?.z ?? this.camera.position.z ?? 0;

    this.cameraOrigin = {
      ...this.cameraOrigin,
      position: {
        x: this.camera.position.x,
        y: this.camera.position.y,
        z: this.camera.position.z,
      },
    };

    this.camera.lookAt(this.cameraTarget);

    return this;
  };

  /**
   * @param {THREE.Vector3} rotation
   */
  setCameraRotation = (rotation) => {
    this.camera.rotation.x = rotation?.x ?? this.camera.rotation.x ?? 0;
    this.camera.rotation.y = rotation?.y ?? this.camera.rotation.y ?? 0;
    this.camera.rotation.z = rotation?.z ?? this.camera.rotation.z ?? 0;

    this.cameraOrigin = {
      ...this.cameraOrigin,
      rotation: {
        x: this.camera.rotation.x,
        y: this.camera.rotation.y,
        z: this.camera.rotation.z,
      },
    };

    this.camera.lookAt(this.cameraTarget);

    return this;
  };

  /**
   * @param {THREE.Vector3} position
   */
  setScenePosition = (position) => {
    this.actorGroup.position.x = position?.x ?? this.actorGroup.position.x ?? 0;
    this.actorGroup.position.y = position?.y ?? this.actorGroup.position.y ?? 0;
    this.actorGroup.position.z = position?.z ?? this.actorGroup.position.z ?? 0;

    this.sceneOrigin = {
      ...this.sceneOrigin,
      position: {
        x: this.actorGroup.position.x,
        y: this.actorGroup.position.y,
        z: this.actorGroup.position.z,
      },
    };

    return this;
  };

  /**
   * @param {THREE.Vector3} rotation
   */
  setSceneRotation = (rotation) => {
    this.actorGroup.rotation.x = rotation?.x ?? this.actorGroup.rotation.x ?? 0;
    this.actorGroup.rotation.y = rotation?.y ?? this.actorGroup.rotation.y ?? 0;
    this.actorGroup.rotation.z = rotation?.z ?? this.actorGroup.rotation.z ?? 0;

    this.sceneOrigin = {
      ...this.sceneOrigin,
      rotation: {
        x: this.actorGroup.rotation.x,
        y: this.actorGroup.rotation.y,
        z: this.actorGroup.rotation.z,
      },
    };

    return this;
  };

  /**
   * @param {THREE.Vector3} args.position
   * @param {THREE.Vector3} args.rotation
   * @param {Number} args.fov
   * @param {Number} args.zoom
   * @param {Number} args.duration
   * @returns {SceneClass}
   */
  moveCameraPosition = (args) => {
    const camera = this.camera;
    const cameraTarget = this.cameraTarget;

    const target = {
      rotation: {
        x: args?.rotation?.x ?? camera.rotation.x,
        y: args?.rotation?.y ?? camera.rotation.y,
        z: args?.rotation?.z ?? camera.rotation.z,
      },
      position: {
        x: args?.position?.x ?? camera.position.x,
        y: args?.position?.y ?? camera.position.y,
        z: args?.position?.z ?? camera.position.z,
      },
      fov: args?.fov ?? camera.fov,
      zoom: args?.zoom ?? camera.zoom,
      lookAt: args?.lookAt ?? {
        x: this.scene.position.x,
        y: this.scene.position.y,
        z: this.scene.position.z,
      },
    };

    const duration = args?.duration ?? 1;
    const delay = args?.delay ?? 0;
    const ease = Power1.easeInOut;

    gsap.to(this.camera.rotation, {
      ...target.rotation,
      duration,
      delay,
      ease,
    });
    gsap.to(this.camera.position, {
      ...target.position,
      duration,
      delay,
      ease,
    });
    gsap.to(this.camera.lookAt, {
      ...target.lookAt,
      duration,
      delay,
      ease,
      onUpdate: () => {
        const { x, y, z } = this.camera.lookAt;

        cameraTarget.x = x;
        cameraTarget.y = y;
        cameraTarget.z = z;

        this.camera.lookAt(new Vector3(x, y, z));
      },
    });
    gsap.to(this.camera, {
      fov: target.fov,
      zoom: target.zoom,
      duration,
      delay,
      ease,
    });

    return this;
  };

  /**
   * @param {THREE.Vector3} args.position
   * @param {Number} args.duration
   * @returns {SceneClass}
   */
  moveScenePosition = (args) => {
    const scene = this.actorGroup;

    const target = {
      position: {
        x: args?.position?.x ?? scene.position.x,
        y: args?.position?.y ?? scene.position.y,
        z: args?.position?.z ?? scene.position.z,
      },
      rotation: {
        x: args?.rotation?.x ?? scene.rotation.x,
        y: args?.rotation?.y ?? scene.rotation.y,
        z: args?.rotation?.z ?? scene.rotation.z,
      },
    };

    const duration = args?.duration ?? 1;
    const delay = args?.delay ?? 0;
    const ease = Power1.easeInOut;

    gsap.to(this.actorGroup.position, {
      ...target.position,
      duration,
      delay,
      ease,
    });
    gsap.to(this.actorGroup.rotation, {
      ...target.rotation,
      duration,
      delay,
      ease,
    });

    return this;
  };

  /**
   * @param {Number} args.duration
   * @returns {SceneClass}
   */
  resetCamera = (args) => {
    this.moveCameraPosition({
      rotation: {
        x: this.cameraOrigin.rotation.x,
        y: this.cameraOrigin.rotation.y,
        z: this.cameraOrigin.rotation.z,
      },
      position: {
        x: this.cameraOrigin.position.x,
        y: this.cameraOrigin.position.y,
        z: this.cameraOrigin.position.z,
      },
      fov: args?.fov,
      duration: args?.duration,
    });

    return this;
  };

  changeBackgroundColor = (args) => {
    if (!args?.color) return this;

    const duration = (args?.duration ?? 1000) / 1000;
    const ease = Power1.easeInOut;

    gsap.to(this.scene.background, {
      ...args.color,
      duration,
      ease,
    });
    gsap.to(this.scene.fog.color, {
      ...args.color,
      duration,
      ease,
    });
    gsap.to(this.plane.plane.material.color, {
      ...args.color,
      duration,
      ease,
    });

    return this;
  };

  showHideGrid = (show) => {
    if (show) this.plane.showGrid();
    else this.plane.hideGrid();

    return this;
  };

  /**
   * @param {Number} args.duration
   * @returns {SceneClass}
   */
  resetScene = (args) => {
    this.moveScenePosition({
      position: {
        x: this.sceneOrigin.position.x,
        y: this.sceneOrigin.position.y,
        z: this.sceneOrigin.position.z,
      },
      rotation: {
        x: this.sceneOrigin.rotation.x,
        y: this.sceneOrigin.rotation.y,
        z: this.sceneOrigin.rotation.z,
      },
      duration: args?.duration,
    });

    return this;
  };

  addResizeHandler = (handler) => {
    this.resizeHandlers.push(handler);

    return this;
  };

  removeResizeHandler = (handler) => {
    this.resizeHandlers.splice(this.resizeHandlers.findIndex(handler), 1);

    return this;
  };
}

let _sceneInstance = null;

/**
 * @returns {SceneClass} The constructed scene.
 */
const Scene = (args) => {
  if (!_sceneInstance) {
    const {
      target,
      backgroundColor,
      breakpoints,
      pixelRatio,
      renderScale,
      onresize,
    } = args ?? {};

    _sceneInstance = new SceneClass({
      target,
      backgroundColor,
      breakpoints,
      pixelRatio,
      renderScale,
      onresize,
    });
  }

  return _sceneInstance;
};

export default Scene;
