import React from "react";
import { VintageFrame, WavesPattern } from "../_assets/2d/Images";
import "./styles/PictureFrame.scss";

export default ({ character, background, rotation, characterAdjustmentX }) => {
  const frameStyles = {};

  if (rotation) frameStyles.transform = `scale(1.1) rotate(${rotation}deg)`;

  const characterStyles = { backgroundImage: `url(${character})` };

  if (characterAdjustmentX)
    characterStyles.backgroundPositionX = `${characterAdjustmentX}px`;

  if (!background) background = WavesPattern;

  return (
    <div className="picture-frame" style={frameStyles}>
      <div
        className="frame"
        style={{ backgroundImage: `url(${VintageFrame})` }}
      />
      <div
        className="background"
        style={{ backgroundImage: `url(${background})` }}
      />
      <div className="character" style={characterStyles} />
    </div>
  );
};
