import * as THREE from "three";
import { Platform, Scene } from "../_common/3d";
import { Dock, Airship } from "../_assets/3d";
import { StateStore } from "../utils";

let initialized = false;
let onInit = null;
let dock, airship;
let cleanupTimeout = null;

const transitionIn = () => {
  Platform().spin();
  Scene()
    .changeBackgroundColor({ color: new THREE.Color(0xffd596) })
    .showHideGrid(true);

  dock.animate().jumpIn(null, 7);
  airship.reset().float(0.01, 0.9).popIn();
};

const transitionOut = () => {
  dock.stop().sinkOut(null, 4);
  airship.popOut(null, 4);

  cleanupTimeout = setTimeout(() => {
    // Perform resource cleanup after scene has transitioned out
    Platform().removeChildren([dock, airship]);
    dock = null;
    airship = null;

    if (!/team|architect|ship/.test(StateStore.retrieve("page")))
      Platform().destroy();

    initialized = false;
    Scene().renderer.renderLists.dispose();
  }, 2000);
};

const create = () => {
  clearTimeout(cleanupTimeout);

  if (initialized) return onInit && onInit();

  dock = Dock({
    position: { y: 0, x: 0, z: 0 },
  });
  airship = Airship({
    delay: 600,
    lod: 2,
    position: { x: -0.9, y: 0.9, z: 1 },
    rotation: { y: -Math.PI / 2 },
    scale: 1.8,
  });

  dock.hide();
  airship.hide();

  Platform({
    children: [dock, airship],
  });

  initialized = true;

  onInit && setTimeout(onInit, 200);
};

const whenReady = (func) => {
  if (!func) return;

  if (!initialized) {
    onInit = func;
    return;
  }

  func();
};

export { create, transitionIn, transitionOut, whenReady };
