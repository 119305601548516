import React, { useEffect, useState } from "react";
import {
  Page,
  DetailHeading,
  DetailContent,
  ScrollDownIndicator,
  Section,
} from "../_common";
import { Scene } from "../_common/3d";
import { CameraPositions, ScenePositions, Scenes } from "../_common/Constants";
import * as ShipScene from "./ShipScene";
import Virtue from "./Virtue";
import "./styles/ShipDetails.scss";
import IndustryIcon from "../_common/IndustryIcon";
import { responsiveHeightSubtraction } from "../utils";
import { AirshipBack, Commitment, Quality, Value } from "../_assets/2d/Images";

const industries = [
  { icon: "insurance", text: "Insurance" },
  { icon: "telco", text: "Telecommunications" },
  { icon: "health", text: "Healthcare" },
  { icon: "forensics", text: "Digital Forensics" },
  { icon: "logistics", text: "Logistics" },
  { icon: "financial", text: "Financial" },
  { icon: "iot", text: "Internet of Things" },
  { icon: "fleet", text: "Fleet Management" },
  { icon: "sport", text: "Sport & Recreation" },
  { icon: "education", text: "Education & Training" },
  { icon: "assets", text: "Asset Management & Tracking" },
  { icon: "hr", text: "Enterprise Human Resources" },
];

export default (props) => {
  const { onMount, screenSize } = props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
    setTimeout(
      () =>
        setHeight(
          window.innerHeight -
            responsiveHeightSubtraction() -
            (window.innerWidth > 810 ? 85 : 65)
        ),
      1500
    );
  }, []);

  useEffect(() => {
    ShipScene.whenReady(() => {
      Scene().moveScenePosition(ScenePositions[screenSize].detail.Ship);
      Scene().moveCameraPosition(CameraPositions[screenSize].detail.Ship);
      onMount && onMount(Scenes.ShipMore);
    });
  }, [onMount, screenSize]);

  if (!loaded) return <></>;

  return (
    <Page
      id="ship-details"
      className="details"
      footer="large"
      style={{ marginTop: height }}
    >
      <ScrollDownIndicator />
      <DetailHeading main="Ship" rest="Quality Software" />
      <DetailContent>
        <Section
          className="airship-back"
          graphic={<div style={{ backgroundImage: `url(${AirshipBack})` }} />}
        >
          <p>
            Using technical jargon to elevate perceived expertise, will not
            navigate your project to its destination. Successful arrival is what
            matters most. As Master Software Crafters, you can rest assured that
            we will deliver your project on time, within budget and to the
            highest standards, without exception.
          </p>
          <p>
            With over two centuries of combined software development experience
            and over 100 projects under our belt, we have the know-how to solve
            your problem and to realise your software ideas.
          </p>
        </Section>
        <Section bodyClasses="virtues">
          <Virtue graphic={<ValueIcon icon={Quality} />} heading="Quality">
            There is no substitute for exceptional quality work. Each
            application we build is structured according to best-practice
            patterns and methodologies and nothing, but the highest standards
            are accepted.
          </Virtue>
          <Virtue graphic={<ValueIcon icon={Value} />} heading="Value">
            As a custom software boutique in the competitive software
            marketplace, we are large enough to have all the expertise required
            to build top-notch systems, yet small enough to keep it affordable
            for you, our client.
          </Virtue>
          <Virtue
            graphic={<ValueIcon icon={Commitment} />}
            heading="Commitment"
          >
            We understand that you are wanting a service that renders a
            solution. So, when you hire us to get a particular outcome, we keep
            working until you get it. That is our guarantee.
          </Virtue>
        </Section>
        <Section
          heading="Industries We've Worked In"
          bodyClasses="industry-icons"
        >
          {industries.map((ind, i) => (
            <IndustryIcon key={`industry-${i}`} {...ind} />
          ))}
        </Section>
      </DetailContent>
    </Page>
  );
};

const ValueIcon = ({ icon }) => (
  <div className="value-icon" style={{ backgroundImage: `url(${icon})` }} />
);
