import React from "react";
import { Technologies } from "../_assets/2d/Images";
import "./styles/TechIcon.scss";

export default ({ icon, text, small }) => (
  <div
    className={`tech-icon ${icon}${small ? " small" : ""}`}
    style={{ backgroundImage: `url(${Technologies})` }}
    title={text}
  />
);
