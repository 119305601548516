import { getAsset } from "../../utils/AssetLoader";
import TransitionableMesh from "../../_common/3d/TransitionableMesh";
import gsap, { Power1 } from "gsap";

class DockClass extends TransitionableMesh {
  constructor(args) {
    super(args);

    const { scene } = getAsset("Models.Dock");
    const scale = 5.7;
    const ctx = this;

    scene.scale.x = scale;
    scene.scale.y = scale;
    scene.scale.z = scale;
    //scene.rotation.y = Math.PI;
    scene.position.y = 0.05;
    scene.position.x = 0;
    scene.position.z = 0;

    scene.traverse((child) => {
      if (
        child.isMesh &&
        child.material.map &&
        /water/.test(child.material.name)
      ) {
        child.material.transparent = true;
        child.material.opacity = 0.8;
        child.material.metalness = 0.0;
        child.material.roughness = 0.0;

        ctx.water = child;
      } else if (child.isMesh && /CRANE1/.test(child.name)) {
        ctx.crane1 = child;
      } else if (child.isMesh && /CRANE2/.test(child.name)) {
        ctx.crane2 = child;
      }
    });

    this.init(scene);
  }

  animate = () => {
    if (this.animating) return this;

    this.water.position.y = 0;

    this.waterFloatTween = gsap.to(this.water.position, {
      y: this.water.position.y - 0.002,
      duration: 1.1,
      repeat: -1,
      yoyo: true,
      ease: Power1.easeInOut,
    });

    this.animating = true;

    return this;
  };

  stop = () => {
    this.waterFloatTween && this.waterFloatTween.kill();
    this.animating = false;

    return this;
  };
}

let instance;

export default (args) => {
  if (!instance) instance = new DockClass(args);

  return instance;
};
