import * as THREE from "three";
import { Scene } from "../_common/3d";
import ThreeScene from "./ThreeScene";

let initialized = false;
let threeScene;
let showing = false;
let cleanupTimeout = null;

const transitionIn = () => {
  if (showing) return;

  showing = true;

  Scene()
    .changeBackgroundColor({ color: new THREE.Color(0x000000) })
    .showHideGrid(false);

  const hemiLight = Scene().getActor((a) => a.key === "hemiLight");
  hemiLight.fadeOut();

  threeScene.startScene();
};

const transitionOut = () => {
  if (!threeScene || !showing) return;

  showing = false;

  const hemiLight = Scene().getActor((a) => a.key === "hemiLight");
  hemiLight.fadeIn();

  threeScene.stopScene();

  cleanupTimeout = setTimeout(() => {
    // Perform resource cleanup after scene has transitioned out
    Scene().remove(threeScene);
    threeScene = null;
    initialized = false;
    Scene().renderer.renderLists.dispose();
  }, 2000);
};

const create = () => {
  clearTimeout(cleanupTimeout);

  if (initialized) return;

  initialized = true;
  threeScene = ThreeScene({
    position: { x: -24, y: 1.3, z: 91.1 },
    rotation: { y: -0.4 },
  });

  Scene().add(threeScene);
};

export { create, transitionIn, transitionOut };
