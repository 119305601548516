import Object3D from "./Object3D";
import gsap, { Linear, Bounce, Power1, Power3, Expo } from "gsap";

export default class TransitionableMesh extends Object3D {
  constructor(args) {
    super();

    this.delay = args?.delay ?? 0;
  }

  reset = (ctx) => {
    this.object3d.position.set(
      this.initialPosition.x,
      this.initialPosition.y,
      this.initialPosition.z
    );
    this.object3d.rotation.set(
      this.initialRotation.x,
      this.initialRotation.y,
      this.initialRotation.z
    );
    this.object3d.scale.set(
      this.initialScale.x,
      this.initialScale.y,
      this.initialScale.z
    );

    ctx &&
      console.log(ctx, {
        pos: this.object3d.position,
        rot: this.object3d.rotation,
        scl: this.object3d.scale,
      });

    return this;
  };

  clear = () => {
    this.rotationTween && this.rotationTween.kill();
    this.swivelTween && this.swivelTween.kill();

    delete this.rotationTween;
    delete this.swivelTween;
  };

  jumpIn = (callback, initial, ctx) => {
    ctx && console.log(ctx, "jumpIn");
    this.lastTransition = "in";

    this.show();
    let start = this.initialPosition.y;

    this.object3d.position.y = start - (initial ?? 3);

    let jumpTimeline = gsap.timeline({
      onComplete: callback ?? function () {},
    });

    jumpTimeline
      .to(this.object3d.position, {
        y: start + 1,
        duration: 0.5,
        delay: 0.5 + this.delay / 1000,
        ease: Power3.easeOut,
      })
      .to(this.object3d.position, {
        y: start,
        duration: 0.65,
        ease: Bounce.easeOut,
      });

    return this;
  };

  popIn = (callback, ctx) => {
    ctx && console.log(ctx, "popIn");
    this.lastTransition = "in";

    this.show();
    const start = this.initialScale.x;

    this.object3d.scale.set(0, 0, 0);

    gsap.to(this.object3d.scale, {
      x: start,
      y: start,
      z: start,
      ease: Bounce.easeOut,
      delay: 0.5 + this.delay / 1000,
      onComplete: callback ?? function () {},
    });

    return this;
  };

  sinkOut = (callback, end, ctx) => {
    ctx && console.log(ctx, "sinkOut");
    this.lastTransition = "out";

    gsap.to(this.object3d.position, {
      y: this.initialPosition.y - (end ?? 1.5),
      ease: Linear.easeIn,
      duration: 0.75,
      onComplete: () => {
        this.lastTransition === "out" && this.hide();
        callback && callback();
      },
    });

    return this;
  };

  popOut = (callback, ctx) => {
    ctx && console.log(ctx, "popOut");
    this.lastTransition = "out";

    gsap.to(this.object3d.scale, {
      x: 0,
      y: 0,
      z: 0,
      ease: Expo.easeOut,
      onComplete: () => {
        this.lastTransition === "out" && this.hide();
        callback && callback();
      },
    });

    return this;
  };

  rotate = (speed) => {
    if (this.rotationTween) return this;

    const current = { y: this.object3d.rotation.y };

    this.rotationTween = gsap.to(this.object3d.rotation, {
      y: current.y + Math.PI * 2,
      duration: speed / 1000,
      repeat: -1,
      ease: Linear.easeNone,
    });

    return this;
  };

  swivel = (speed, amount) => {
    if (this.swivelTween) return this;

    const current = { y: this.object3d.rotation.y };

    this.swivelTween = gsap.to(this.object3d.rotation, {
      y: current.y - (amount ?? Math.PI),
      duration: speed / 1000,
      ease: Power1.easeInOut,
      repeat: -1,
      yoyo: true,
    });

    return this;
  };

  updatePosition = (position, reset) => {
    this.object3d.position.x = position.x ?? this.object3d.position.x;
    this.object3d.position.y = position.y ?? this.object3d.position.y;
    this.object3d.position.z = position.z ?? this.object3d.position.z;

    if (reset)
      this.initialPosition = {
        x: this.object3d.position.x,
        y: this.object3d.position.y,
        z: this.object3d.position.z,
      };

    return this;
  };

  updateRotation = (rotation, reset) => {
    this.object3d.rotation.x = rotation.x ?? this.object3d.rotation.x;
    this.object3d.rotation.y = rotation.y ?? this.object3d.rotation.y;
    this.object3d.rotation.z = rotation.z ?? this.object3d.rotation.z;

    if (reset)
      this.initialRotation = {
        x: this.object3d.rotation.x,
        y: this.object3d.rotation.y,
        z: this.object3d.rotation.z,
      };

    return this;
  };

  updateScale = (scale, reset) => {
    scale = scale?.scale ?? this.object3d.scale.x;
    this.object3d.scale.set(scale, scale, scale);

    if (reset)
      this.initialScale = {
        x: this.object3d.scale.x,
        y: this.object3d.scale.y,
        z: this.object3d.scale.z,
      };

    return this;
  };
}
