import React, { useEffect } from "react";
import * as TeamScene from "./TeamScene";

export default (props) => {
  const { children } = props;

  useEffect(() => {
    TeamScene.create();
    TeamScene.transitionIn();

    return TeamScene.transitionOut;
  }, []);

  return <>{children}</>;
};
