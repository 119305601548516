import React from "react";
import "./styles/SectionHeading.scss";

/**
 * @param {string} main
 * @param {string} rest
 */
export default (props) => {
  const { children } = props;

  return (
    <h1 className="section-heading">
      {children}
      <span />
    </h1>
  );
};
