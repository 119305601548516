import React, { useEffect, useState } from "react";
import { responsiveHeightSubtraction } from "../utils";
import { Tools, WorkshopTable } from "../_assets/2d/Images";
import {
  Page,
  DetailHeading,
  DetailContent,
  ScrollDownIndicator,
  Section,
  TechIcon,
} from "../_common";
import { Scene } from "../_common/3d";
import { CameraPositions, ScenePositions, Scenes } from "../_common/Constants";
import * as ArchitectScene from "./ArchitectScene";
import "./styles/ArchitectDetails.scss";

const techIcons = [
  { icon: "android", text: "Android" },
  { icon: "angular", text: "Angular" },
  { icon: "aws", text: "Amazon Web Services (AWS)" },
  { icon: "csharp", text: "C#" },
  { icon: "css3", text: "CSS3" },
  { icon: "dart", text: "Dart" },
  { icon: "docker", text: "Docker" },
  { icon: "electron", text: "Electron" },
  { icon: "flutter", text: "Flutter" },
  { icon: "html5", text: "HTML5" },
  { icon: "ionic", text: "Ionic" },
  { icon: "java", text: "Java" },
  { icon: "javascript", text: "JavaScript" },
  { icon: "kendo", text: "Kendo" },
  { icon: "kotlin", text: "Kotlin" },
  { icon: "laravel", text: "Laravel" },
  { icon: "mongo", text: "MongoDB" },
  { icon: "mysql", text: "MySQL" },
  { icon: "node", text: "Node.js" },
  { icon: "oracle", text: "Oracle" },
  { icon: "php", text: "PHP" },
  { icon: "react", text: "React.js & React Native" },
  { icon: "sqlserver", text: "Microsoft SQL Server" },
  { icon: "threejs", text: "Three.js" },
  { icon: "vue", text: "Vue.js" },
  { icon: "xml", text: "XML" },
  { icon: "xsl", text: "XSL & XSLT" },
];

export default (props) => {
  const { onMount, screenSize } = props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
    setTimeout(
      () =>
        setHeight(
          window.innerHeight -
            responsiveHeightSubtraction() -
            (window.innerWidth > 810 ? 85 : 65)
        ),
      1500
    );
  }, []);

  useEffect(() => {
    ArchitectScene.whenReady(() => {
      Scene().moveScenePosition(ScenePositions[screenSize].detail.Architect);
      Scene().moveCameraPosition(CameraPositions[screenSize].detail.Architect);
      onMount && onMount(Scenes.ArchitectMore);
    });
  }, [onMount, screenSize]);

  if (!loaded) return <></>;

  return (
    <Page
      id="architect-details"
      className="details"
      footer="large"
      style={{ marginTop: height }}
    >
      <ScrollDownIndicator />
      <DetailHeading main="Architect" rest="A Lasting Solution" />
      <DetailContent>
        <Section
          className="workshop-table"
          graphic={<div style={{ backgroundImage: `url(${WorkshopTable})` }} />}
        >
          <p>
            When embarking on a software development journey, choosing the right
            technologies for your project, is a critically important step to
            ensuring your product or service delivers successfully. We guide our
            clients at every point of our journey, inspiring confidence that
            your decisions are always informed by expert opinion and
            accountability.
          </p>
          <p>
            As Master Software Crafters, we know what tools work best for the
            job at hand, having gleaned implicit understanding of technologies,
            based on their reliability and stability, and for their versatility
            within projects ranging from enterprise-wide systems to single
            applications.
          </p>
        </Section>
        <Section
          bodyClasses="tech-icons"
          heading="Technologies We've Worked In"
        >
          {techIcons.map((t, i) => (
            <TechIcon key={`tech-icon-${i}`} {...t} />
          ))}
        </Section>
        <Section
          heading="Your Rules, Your Way"
          className="tools"
          graphic={<div style={{ backgroundImage: `url(${Tools})` }} />}
        >
          <p>
            If you have preferences for specific technologies, our team will
            find optimal ways to employ them. Conversely, should you require
            complete assistance in selecting every tool for the job, we will
            happily walk you through all aspects of consideration.
          </p>
        </Section>
      </DetailContent>
    </Page>
  );
};
