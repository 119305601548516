import React, { useEffect, useState } from "react";
import { responsiveHeightSubtraction } from "../utils";
import {
  Page,
  SummaryHeading,
  SummaryDescription,
  ReadMoreButton,
  Routes,
} from "../_common";
import { Scene } from "../_common/3d";
import { CameraPositions, ScenePositions, Scenes } from "../_common/Constants";
import * as ExperienceScene from "./ExperienceScene";

export default (props) => {
  const { onMount, screenSize } = props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
  }, []);

  useEffect(() => {
    ExperienceScene.whenReady(() => {
      Scene().moveScenePosition(ScenePositions[screenSize].summary.Experience);
      Scene().moveCameraPosition(
        CameraPositions[screenSize].summary.Experience
      );
      onMount && onMount(Scenes.Experience);
    });
  }, [onMount, screenSize]);

  if (!loaded) return <></>;

  return (
    <Page
      id="experience"
      className="summary"
      footer="large"
      style={{ paddingTop: height }}
    >
      <div className="summary-content">
        <SummaryHeading main="Experience" rest="Something Breathtaking" />
        <SummaryDescription>
          Modern users expect more than just working software. We specialize
          in building experiences that will leave your users craving more. 
        </SummaryDescription>
        <ReadMoreButton location={`${Routes.Experience}${Routes.More}`} />
      </div>
    </Page>
  );
};
