import React from "react";
import { Link } from "@reach/router";
import "./styles/ReadMoreButton.scss";

export default (props) => {
  const { location, children } = props;

  return (
    <Link to={location} className="read-more">
      {children ?? "Read More"}
    </Link>
  );
};
