import React, { useEffect, useRef, useState } from "react";
import { Header, Routes } from "./_common";
import { Router, LocationProvider } from "@reach/router";
import * as Pages from "./Pages";
import { Scene, HemiLight } from "./_common/3d";
import { CameraPositions, ScenePositions } from "./_common/Constants";
import { Loader } from "./loader";
import * as THREE from "three";
import * as Scenes from "./ThreeScenes";
import { loadAssets, StateStore } from "./utils";
import ReactGA from "react-ga";

export default () => {
  const breakpoints = [
    { maxWidth: 1000, height: "100%", size: "mobile" },
    { maxWidth: 1500, height: "100%", size: "tablet" },
    { maxWidth: 100000, height: "100%", size: "full" },
  ];
  const determineScreenSize = (width) => {
    for (let bp of breakpoints) if (width <= bp.maxWidth) return bp.size;

    return "full";
  };
  const mounted = useRef(false);
  const currentPage = useRef("");
  const [loaded, setLoaded] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState({
    loaded: 0,
    total: 0,
  });
  const [screenSize, setScreenSize] = useState(
    determineScreenSize(window.innerWidth)
  );

  const sceneResize = (width) => {
    setScreenSize(determineScreenSize(width));
  };

  const handlePageMount = (page) => {
    window.scrollTo(0, 0);

    ReactGA.pageview(Routes.getRouteFromScene(page));

    currentPage.current = page;
    StateStore.publish("page", page);

    if (page !== "main") Scenes.Main.transitionOut();
    if (page !== "contact") Scenes.Contact.transitionOut();
    if (page !== "experience" && page !== "experience/more")
      Scenes.Experience.transitionOut();
  };

  useEffect(() => {
    ReactGA.initialize("UA-192882727-3", {
      gaOptions: { siteSpeedSampleRate: 100 },
    });

    loadAssets(
      () => {
        setLoaded(true);
        setTimeout((_) => {
          const canvas = document.getElementById("three-scene");
          canvas.classList.add("show");
        }, 10);
      },
      (progress, count) => {
        setLoadingProgress({ loaded: progress, total: count });
      }
    );
  }, []);

  if (!loaded) return <Loader {...loadingProgress} />;

  if (!mounted.current) {
    mounted.current = true;

    const hemiLight = HemiLight({
      key: "hemiLight",
      skyColor: 0xfdf4dc,
      groundColor: 0x3b4248,
      intensity: 0.6,
    });

    Scene({
      target: document.getElementById("root"),
      backgroundColor: new THREE.Color(0x000000),
      breakpoints: breakpoints,
      pixelRatio:
        window.devicePixelRatio <= 1 ? 1.2 : window.devicePixelRatio * 0.7,
      renderScale: 1,
      onresize: sceneResize,
    }).add(hemiLight);

    //Object.keys(Scenes).forEach((k) => Scenes[k].create());

    Scene()
      .setCameraPosition(CameraPositions[screenSize].initial.position)
      .setCameraRotation(CameraPositions[screenSize].initial.rotation)
      .setScenePosition(ScenePositions[screenSize].initial.position)
      .animate();
  }

  return (
    <LocationProvider>
      <Header currentPage={currentPage.current} />
      <Router>
        <Pages.Main
          path={Routes.Home}
          onMount={handlePageMount}
          screenSize={screenSize}
        />
        <Pages.Team.Team path={Routes.Team}>
          <Pages.Team.TeamSummary
            onMount={handlePageMount}
            path={Routes.Root}
            screenSize={screenSize}
          />
          <Pages.Team.TeamDetails
            onMount={handlePageMount}
            path={Routes.More}
            screenSize={screenSize}
          />
        </Pages.Team.Team>
        <Pages.Architect.Architect path={Routes.Architect}>
          <Pages.Architect.ArchitectSummary
            onMount={handlePageMount}
            path={Routes.Root}
            screenSize={screenSize}
          />
          <Pages.Architect.ArchitectDetails
            path={Routes.More}
            onMount={handlePageMount}
            screenSize={screenSize}
          />
        </Pages.Architect.Architect>
        <Pages.Ship.Ship path={Routes.Ship}>
          <Pages.Ship.ShipSummary
            onMount={handlePageMount}
            path={Routes.Root}
            screenSize={screenSize}
          />
          <Pages.Ship.ShipDetails
            onMount={handlePageMount}
            path={Routes.More}
            screenSize={screenSize}
          />
        </Pages.Ship.Ship>
        <Pages.Experience.Experience path={Routes.Experience}>
          <Pages.Experience.ExperienceSummary
            onMount={handlePageMount}
            path={Routes.Root}
            screenSize={screenSize}
          />
          <Pages.Experience.ExperienceDetails
            onMount={handlePageMount}
            path={Routes.More}
            screenSize={screenSize}
          />
        </Pages.Experience.Experience>
        <Pages.Contact
          path={Routes.Contact}
          onMount={handlePageMount}
          screenSize={screenSize}
        />
      </Router>
      {/* <Footer /> */}
    </LocationProvider>
  );
};
