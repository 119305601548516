import React from "react";
import SectionGraphic from "./SectionGraphic";
import SectionHeading from "./SectionHeading";
import "./styles/Section.scss";

/**
 * @param {string} main
 * @param {string} rest
 */
export default ({
  heading,
  graphic,
  children,
  className,
  bodyClasses,
  transparentBackground,
}) => {
  let classes = ["content-section"];

  if (className) classes = classes.concat(className.split(" "));

  return (
    <div className={classes.join(" ")}>
      {heading && <SectionHeading>{heading}</SectionHeading>}
      {graphic && (
        <SectionGraphic transparentBackground={transparentBackground ?? false}>
          {graphic}
        </SectionGraphic>
      )}
      <div className={`content-body ${bodyClasses ?? ""}`}>{children}</div>
    </div>
  );
};
