import * as THREE from "three";
import { getAsset } from "../../utils/AssetLoader";
import { Particles, TransitionableMesh } from "../../_common/3d";
import { Airship } from ".";
import { fixMaterials } from "../../utils/AssetUtils";

class CityClass extends TransitionableMesh {
  constructor(args) {
    super(args);

    const { scene } = getAsset("Models.City");

    fixMaterials(scene);
    scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;

        if (/floor/.test(child.material.name)) {
          child.material.transparent = true;
          child.material.color = new THREE.Color(0x000000);
          child.material.map = getAsset("Images.SkyCityMesh");
        }
      }
    });

    const scale = 2.3;

    scene.scale.x = scale;
    scene.scale.y = scale;
    scene.scale.z = scale;
    //scene.rotation.y = 1.68;

    const group = new THREE.Group();
    group.add(scene);

    group.position.y = 20;
    group.position.x = 0;
    group.position.z = 0;

    const dust = Particles({
      gravity: 0.01,
      scale: 90,
      speed: 0.8,
      size: 0.6,
      ceilY: 0.3,
      floorY: -0.1,
    });

    const airship = Airship({
      delay: 600,
      lod: 2,
      position: { x: -2, y: -4, z: -7 },
      rotation: { y: 5, z: 1 },
      scale: 1,
    });

    if (!args) args = {};

    if (!args.children) args.children = [];

    args.children.push(dust);
    args.children.push(airship);

    if (args?.children?.length) {
      this.children = args.children;
      args.children.map((c) => group.add(c.object3d));
    }

    this.init(group, args);
    this.dust = dust;
    this.airship = airship;
  }

  start = () => {
    this.dust.start();
    this.airship
      .reset()
      .flyIn(
        { x: -0.3, y: 0.21, z: -1.0 },
        { y: 5.5, z: 0 },
        4000,
        1000,
        this.airship.float
      );
    return this;
  };

  stop = () => {
    this.clear();
    this.dust.stop();
    this.airship.stop();
    return this;
  };

  reset = () => {
    this.object3d.rotation.y = 1.3;
    this.object3d.position.y = 20;
    this.object3d.position.x = 0;
    this.object3d.position.z = 0;
    return this;
  };
}

let instance;

export default (args) => {
  if (!instance) instance = new CityClass(args);

  return instance;
};
