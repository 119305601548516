import * as THREE from "three";
import { getAsset } from "../../utils/AssetLoader";
import TransitionableMesh from "../../_common/3d/TransitionableMesh";
import { Color, MeshPhongMaterial } from "three";
import { StateStore } from "../../utils";
import { PointLight } from "../../_common/3d";

class ThumbTackClass extends TransitionableMesh {
  constructor(args) {
    super(args);

    this.stateKey = args.stateKey;

    let { scene } = getAsset("Models.ThumbTack");
    const scale = args?.scale;
    const group = new THREE.Group();

    group.position.x = args?.position?.x ?? 0;
    group.position.y = args?.position?.y ?? 0;
    group.position.z = args?.position?.z ?? 0;

    scene = scene.clone();
    scene.traverse((child) => {
      if (!child.isMesh) return;

      child.material = new MeshPhongMaterial({
        color: new Color(0xffffff),
        shininess: 100,
        specular: new Color(0xffffff),
        emissive: new Color(0x00afef),
        emissiveIntensity: 0,
      });
    });

    scene.scale.x = scale;
    scene.scale.y = scale;
    scene.scale.z = scale;
    scene.rotation.x = args?.rotation?.x ?? 0;
    scene.rotation.y = args?.rotation?.y ?? 0;
    scene.rotation.z = args?.rotation?.z ?? 0;

    group.add(scene);

    if (window.innerWidth > 700) {
      this.light = PointLight({
        intensity: 1,
        decay: 10,
        distance: 1,
        color: new Color(0x00afef),
        shadow: { case: false },
      }).object3d;
      this.light.visible = false;

      group.add(this.light);
    }

    this.init(group);

    StateStore.subscribe("location-details", this, (key) => {
      if (key === this.stateKey && !this.clicked) this.onClick();

      if (key !== this.stateKey && this.clicked) this.onClick();
    });
  }

  onClick = () => {
    this.clicked = this.clicked ? false : true;
    this.object3d.traverse((child) => {
      if (!child.isMesh) return;

      child.material.emissiveIntensity = this.clicked ? 10 : 0;
    });

    if (this.clicked) StateStore.publish("location-details", this.stateKey);

    if (this.light) this.light.visible = this.clicked;
  };

  onMouseOver = () => {
    if (this.clicked) return;

    this.object3d.traverse((child) => {
      if (!child.isMesh) return;

      child.material.emissiveIntensity = 0.5;
    });
  };

  onMouseOut = () => {
    if (this.clicked) return;

    this.object3d.traverse((child) => {
      if (!child.isMesh) return;

      child.material.emissiveIntensity = 0;
    });
  };
}

export default (args) => new ThumbTackClass(args);
