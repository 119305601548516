import React from "react";

export default (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="400"
    height="267"
    viewBox="0 0 800 534"
  >
    <rect x="0" y="0" width="100%" height="100%" fill="#000C8A" />
    <rect x="0" y="0" width="100%" height="50%" fill="#E1392D" />
    <path
      d="M 0,0 l 160,0 l 266,178 l 374,0 l 0,178 l -374,0 l -266,178 l -160,0 z"
      fill="#FFFFFF"
    />
    <path
      d="M 0,0 l 97 ,0 l 318,213 l 385,0 l 0,108 l -385,0 l -318,213 l -97,0 z"
      fill="#007847"
    />
    <path d="M 0,65 l 304,202 l -304,202 z" fill="#FFB915" />
    <path d="M 0,107 l 240,160 l -240,160 z" fill="#000000" />
  </svg>
);
