import React, { useEffect, useState } from "react";
import {
  Page,
  DetailHeading,
  DetailContent,
  ScrollDownIndicator,
  Section,
} from "../_common";
import { Scene } from "../_common/3d";
import { ScenePositions, CameraPositions, Scenes } from "../_common/Constants";
import * as TeamScene from "./TeamScene";
import "./styles/team-details.scss";
import PictureFrame from "./PictureFrame";
import {
  Alchemist,
  Artist,
  Captain,
  Engineer,
  Inventor,
  Muscle,
  Navigator,
} from "../_assets/2d/Images";
import { responsiveHeightSubtraction } from "../utils";

export default (props) => {
  const { onMount, screenSize } = props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
    setTimeout(
      () =>
        setHeight(
          window.innerHeight -
            responsiveHeightSubtraction() -
            (window.innerWidth > 810 ? 85 : 65)
        ),
      1500
    );
  }, []);

  useEffect(() => {
    TeamScene.whenReady(() => {
      Scene().moveScenePosition(ScenePositions[screenSize].detail.Team);
      Scene().moveCameraPosition(CameraPositions[screenSize].detail.Team);
      onMount && onMount(Scenes.TeamMore);
    });
  }, [screenSize, onMount]);

  if (!loaded) return <></>;

  return (
    <Page
      id="team-details"
      className="details"
      footer="large"
      style={{ marginTop: height }}
    >
      <ScrollDownIndicator />
      <DetailHeading main="Team" rest="Up" />
      <DetailContent>
        <Section>
          <p>
            Each one of our crafters has a drive to reduce the complexity in the
            projects they undertake and reuse the wealth of knowledge, assets
            and practices we've accumulated and refined over the years. A subset
            of team-based services we provide include:
          </p>
          <ul>
            <li>
              <em>Outsourced Development:</em> Don’t have the capacity to
              develop software, or simply want to avoid the expense and headache
              of having to hire an internal development team yourself? Reduce
              your costs and inefficiencies and mitigate your risks by
              outsourcing your development to our experienced development team.
            </li>
            <li>
              <em>Team Augmentation:</em> Do you have a software specific skills
              gap within your organization or project? Whether it’s a temporary
              assignment or a full-time appointment, our proficient development
              team can staff your project and fill any gap seamlessly, anywhere
              in the world.
            </li>
            <li>
              <em>Software Audits:</em> Experience and reputation should be
              paramount in choosing the right independent auditor for your
              software application or process. With our expansive expertise in
              regulatory compliance and best practice adherence, you can entrust
              us with this valued undertaking.
            </li>
            <li>
              <em>Project Consultation:</em> Regardless of what stage of
              development your project is, we can render valuable consultancy
              that serves to expedite the resolution of your technical problems
              and informs your technological business decisions going forward
            </li>
            <li>
              <em>Expert Opinions:</em> Whether you require digital forensic
              expertise for a pending legal case or need our assistance in
              deciphering effective risk management strategies for development
              of your application, we have earned our reputation as leaders in
              the software development field and our input is often sort in this
              capacity.
            </li>
          </ul>
        </Section>
        <Section
          heading="The Captain"
          graphic={
            <PictureFrame
              character={Captain}
              characterAdjustmentX={-75}
              rotation={-5}
            />
          }
          transparentBackground
        >
          <p>
            A master in their respective craft, be it software development or
            digital artistry, the Captain is responsible for leading, coaching
            and mentoring their team to ensure all projects exude excellence
            from conception to delivery.
          </p>
          <p>
            <em>Core Values:</em> Steps up to the plate. Takes ownership and
            responsibility.
          </p>
        </Section>
        <Section
          heading="The Navigator"
          graphic={<PictureFrame character={Navigator} rotation={7} />}
          transparentBackground
        >
          <p>
            Managing the process of project development, the Navigator
            coordinates team members’ inputs in sync with client expectations to
            ensure delivery deadlines are always honored.
          </p>
          <p>
            <em>Core Values:</em> Communicates with purpose, clarity and
            integrity.
          </p>
        </Section>
        <Section
          heading="The Engineer"
          graphic={<PictureFrame character={Engineer} rotation={-3} />}
          transparentBackground
        >
          <p>
            Solution driven, the Engineer develops creative systems and
            processes that solve complex problems and realize vast opportunities
            through the canvas of technology.
          </p>
          <p>
            <em>Core Values:</em> Sees every obstacle as an opportunity. Always
            thinking outside the box.{" "}
          </p>
        </Section>
        <Section
          heading="The Inventor"
          graphic={<PictureFrame character={Inventor} rotation={5} />}
          transparentBackground
        >
          <p>
            Architecting the project for the development team, the Inventor
            determines which processes and technologies are best suited to an
            application’s objectives and individual client needs.
          </p>
          <p>
            <em>Core Values:</em> Informed decision maker. Maximizes efficiency
            through system cohesion.
          </p>
        </Section>
        <Section
          heading="The Artist"
          graphic={
            <PictureFrame
              character={Artist}
              characterAdjustmentX={-140}
              rotation={-7}
            />
          }
          transparentBackground
        >
          <p>
            Having an implicit understanding of how users engage with
            applications and systems is the role of the Software Artist who
            curates this knowledge into the most engaging user journeys and
            experiences imaginable.{" "}
          </p>
          <p>
            <em>Core Values:</em> Exercises creativity underpinned by empathy.
          </p>
        </Section>
        <Section
          heading="The Muscle"
          graphic={<PictureFrame character={Muscle} rotation={3} />}
          transparentBackground
        >
          <p>
            Providing the Muscle behind every project is the software developer
            who serves to advance development by fulfilling specific roles and
            crucial tasks such as writing code, merging databases and testing
            software.
          </p>
          <p>
            <em>Core Values:</em> Conscientious. Pays close attention to every
            detail.
          </p>
        </Section>
        <Section
          heading="The Alchemist"
          graphic={<PictureFrame character={Alchemist} rotation={-5} />}
          transparentBackground
        >
          <p>
            Beyond the confines of code and brief adherence, and without
            exception to any individual function, designation or person, our
            team members’ passion for their craft is our alchemy. Projects are
            the personification of this “magic”.
          </p>
          <p>
            <em>Core Values:</em> Unrivalled passion. Unlimited capacity.
          </p>
        </Section>
      </DetailContent>
    </Page>
  );
};
