import * as THREE from "three";
import { getAsset } from "../utils/AssetLoader";
import { ThumbTack } from "../_assets/3d";
import { SpotLight, TransitionableMesh } from "../_common/3d";
import gsap, { Power1 } from "gsap";

class ThreeSceneClass extends TransitionableMesh {
  constructor(args) {
    super(args);

    const group = new THREE.Group();

    group.position.x = args?.position?.x ?? 0;
    group.position.y = args?.position?.y ?? 0;
    group.position.z = args?.position?.z ?? 0;
    group.rotation.x = args?.rotation?.x ?? 0;
    group.rotation.y = args?.rotation?.y ?? 0;
    group.rotation.z = args?.rotation?.z ?? 0;

    const tableGeo = new THREE.BoxGeometry(3, 0.1, 3);
    const tableMat = new THREE.MeshPhongMaterial({
      color: 0x964b00,
      map: getAsset("Images.WoodenTexture"),
      shininess: 10,
      specular: new THREE.Color(0xffffff),
    });
    const tableMesh = new THREE.Mesh(tableGeo, tableMat);
    tableMesh.castShadow = true;
    tableMesh.receiveShadow = true;
    tableMesh.position.y = -1.85;

    const mapGeo = new THREE.BoxGeometry(1.76, 0.001, 1);
    const mapMat = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      map: getAsset("Images.WorldMapTexture"),
      shininess: 10,
      specular: new THREE.Color(0x000000),
    });
    const mapMesh = new THREE.Mesh(mapGeo, mapMat);
    mapMesh.castShadow = true;
    mapMesh.receiveShadow = true;
    mapMesh.position.y = -1.8;
    mapMesh.rotation.y = -0.5;
    mapMesh.scale.x = 1.4;
    mapMesh.scale.z = 1.4;

    group.add(tableMesh);
    group.add(mapMesh);

    this.capeTownPin = ThumbTack({
      position: { x: 0.16, y: -1.76, z: 0.38 },
      rotation: { x: 0, z: 0.3, y: 0 },
      scale: 0.02,
      color: new THREE.Color(0x96ffd8),
      stateKey: "Cape Town",
    });
    this.centurionPin = ThumbTack({
      position: { x: 0.215, y: -1.76, z: 0.352 },
      rotation: { x: -0.1, z: -0.2, y: 0 },
      scale: 0.02,
      color: new THREE.Color(0x54d1ff),
      stateKey: "Centurion",
    });
    this.austriaPin = ThumbTack({
      position: { x: 0.427, y: -1.76, z: -0.11 },
      rotation: { x: -0.3, z: 0.1, y: 0 },
      scale: 0.02,
      color: new THREE.Color(0x888888),
      stateKey: "Vienna",
    });

    group.add(this.capeTownPin.object3d);
    group.add(this.centurionPin.object3d);
    group.add(this.austriaPin.object3d);

    this.light = SpotLight({
      color: new THREE.Color(0xffffff),
      distance: 11,
      intensity: 8,
      decay: 2.3,
      angle: Math.PI / 8,
      penumbra: 2,
      shadow: {
        far: 1,
      },
      position: { x: 7, y: 2.5, z: 1 },
      target: { x: 5, y: 0, z: 1 },
    });

    group.add(this.light.object3d);
    group.add(this.light.target);

    this.init(group, args);

    this.table = tableMesh;
    this.map = mapMesh;
  }

  startScene = () => {
    if (this.animating) return;

    this.animating = true;

    if (!this.lightInitialIntensity)
      this.lightInitialIntensity = this.light.object3d.intensity;

    if (!this.lightInitialPosX)
      this.lightInitialPosX = this.light.object3d.position.x;

    if (!this.lightInitialPosZ)
      this.lightInitialPosZ = this.light.object3d.position.z;

    this.light.object3d.intensity = this.lightInitialIntensity;
    this.light.object3d.position.x = this.lightInitialPosX;
    this.light.object3d.position.z = this.lightInitialPosZ;

    const tl = gsap.timeline({ repeat: -1, yoyo: true });
    tl.to(this.light.object3d, {
      intensity: this.lightInitialIntensity + 0.5,
      ease: Power1.easeInOut,
      duration: 0.6,
    });
    tl.to(this.light.object3d.position, {
      x: this.lightInitialPosX - 0.1,
      z: this.lightInitialPosZ - 0.1,
      ease: Power1.easeInOut,
      duration: 0.8,
    });
    tl.to(this.light.object3d, {
      intensity: this.lightInitialIntensity + 0.2,
      ease: Power1.easeInOut,
      duration: 0.4,
    });
    tl.to(this.light.object3d.position, {
      x: this.lightInitialPosX + 0.1,
      z: this.lightInitialPosZ,
      ease: Power1.easeInOut,
      duration: 0.6,
    });
    tl.to(this.light.object3d, {
      intensity: this.lightInitialIntensity + 0.7,
      ease: Power1.easeInOut,
      duration: 0.2,
    });

    this.flickerTween1 = tl;

    return this;
  };

  stopScene = () => {
    if (!this.animating) return;

    this.animating = false;
    this.flickerTween1 && this.flickerTween1.kill();
    this.flickerTween2 && this.flickerTween2.kill();

    return this;
  };

  getClickableObjects = () => [
    this.capeTownPin,
    this.centurionPin,
    this.austriaPin,
  ];
}

let instance;

export default (args) => {
  if (!instance) instance = new ThreeSceneClass(args);

  return instance;
};
