import { destroyObject } from "../../utils/AssetUtils";

export default class Object3D {
  constructor(args) {
    this.children = [];
    this.inititialized = false;

    if (args?.key) this.key = args.key;
  }

  init = (object3d, args, animationMixer) => {
    if ((args?.shadow?.cast ?? null) !== null)
      object3d.castShadow = args?.shadow?.cast;

    if ((args?.shadow?.receive ?? null) !== null)
      object3d.receiveShadow = args?.shadow?.receive;

    this.object3d = object3d;
    this.animationMixer = animationMixer;
    this.lastFrame = new Date().getTime();
    this.inititialized = true;

    this.initialPosition = {
      x: this.object3d.position.x,
      y: this.object3d.position.y,
      z: this.object3d.position.z,
    };
    this.initialRotation = {
      x: this.object3d.rotation.x,
      y: this.object3d.rotation.y,
      z: this.object3d.rotation.z,
    };
    this.initialScale = {
      x: this.object3d.scale.x,
      y: this.object3d.scale.y,
      z: this.object3d.scale.z,
    };
  };

  show = (delay) => {
    if (this.visibleTimeout) clearTimeout(this.visibleTimeout);

    const obj = this.object3d;

    this.visibleTimeout = setTimeout((_) => {
      obj.visible = true;
    }, delay ?? 0);

    return this;
  };

  hide = (delay) => {
    if (this.visibleTimeout) clearTimeout(this.visibleTimeout);

    const obj = this.object3d;

    this.visibleTimeout = setTimeout((_) => {
      obj.visible = false;
    }, delay ?? 0);

    return this;
  };

  addChildren = (children) => {
    if (!children?.length) return;

    children.forEach((c) => {
      if (
        this.object3d.children.findIndex((o) => o.uuid === c.object3d.uuid) >= 0
      )
        return c;

      this.children.push(c);
      this.object3d.add(c.object3d);
    });
  };

  removeChildren = (children) => {
    if (!children?.length) return;

    children.forEach((c) => {
      const index =
        this.object3d.children.findIndex((o) => o.uuid === c.object3d.uuid) - 1;

      if (index < 0) return;

      this.children.splice(index, 1);
      c.destroy();
    });
  };

  updateAnimationMixer = () => {
    if (this.animationStopped || !this.animationMixer) return;

    const newFrame = new Date().getTime();
    const delta = (newFrame - this.lastFrame) / 1000;

    this.animationMixer.update(delta);
    this.lastFrame = newFrame;
  };

  stopAnimation = () => {
    this.animationStopped = true;
    return this;
  };

  startAnimation = () => {
    this.animationStopped = false;
    return this;
  };

  animate = () => {
    if (!this.inititialized) {
      console.error(
        "Object not initialized yet. Run init(object3d, args) first."
      );

      return;
    }

    this.children.forEach((c) => {
      c.updateAnimationMixer && c.updateAnimationMixer();
      c.updateFrame && c.updateFrame();
    });
    this.updateAnimationMixer();
    this.updateFrame && this.updateFrame();
  };

  destroy() {
    destroyObject(this.object3d);
  }
}
