import * as THREE from "three";
import { SpotLight, TransitionableMesh } from "../_common/3d";
import { Captain, Droid, Navigator, Airship } from "../_assets/3d";

class ThreeSceneClass extends TransitionableMesh {
  constructor(args) {
    super(args);

    if (!args) args = {};

    if (!args.children) args.children = [];

    const group = new THREE.Group();

    group.position.x = args?.position?.x ?? 0;
    group.position.y = args?.position?.y ?? 0;
    group.position.z = args?.position?.z ?? 0;
    group.rotation.x = args?.rotation?.x ?? 0;
    group.rotation.y = args?.rotation?.y ?? 0;
    group.rotation.z = args?.rotation?.z ?? 0;

    this.actorScale = 50;

    this.captainPosition = { x: 0, y: -0.9, z: 0 };
    this.captainRotation = { x: 0, y: 0, z: 0 };
    this.captain = Captain({
      lod: 2,
      position: this.captainPosition,
      rotation: this.captainRotation,
      scale: this.actorScale,
      delay: 100,
    });
    args.children.push(this.captain);

    this.navigatorPosition = { x: 2, y: -0.9, z: -2 };
    this.navigatorRotation = { x: 0, y: 0.3, z: 0 };
    this.navigatorScale = 50;
    this.navigator = Navigator({
      lod: 2,
      position: this.navigatorPosition,
      rotation: this.navigatorRotation,
      scale: this.actorScale,
      delay: 200,
    });
    args.children.push(this.navigator);

    this.droidPosition = { x: -2, y: -0.9, z: -2 };
    this.droidRotation = { x: 0, y: -0.3, z: 0 };
    this.droid = Droid({
      lod: 2,
      position: this.droidPosition,
      rotation: this.droidRotation,
      scale: this.actorScale,
      delay: 300,
    });
    args.children.push(this.droid);

    this.airship = Airship({
      lod: 2,
      position: { x: 1.5, y: 5, z: -1 },
      rotation: { z: 0.05, y: -0.9 },
      scale: 12,
    });
    args.children.push(this.airship);

    this.light = SpotLight({
      color: new THREE.Color(0x999999),
      position: { x: 0, y: 15, z: 10 },
      distance: 50,
      intensity: 8.0,
      angle: Math.PI / 5,
      decay: 2,
      target: { x: 0, y: 5, z: -22 },
    });
    args.children.push(this.light);

    if (args?.children?.length) {
      this.children = args.children;
      args.children.forEach((c) => {
        group.add(c.object3d);
        c.target && group.add(c.target);
      });
    }

    this.init(group, args);
  }

  startScene = () => {
    if (this.animating) return;

    this.animating = true;
    this.airship.float(0.1, 1.5);
    this.captain.reset().startAnimation().show();
    this.navigator.reset().startAnimation().show();
    this.droid.reset().startAnimation().show();

    return this;
  };

  stopScene = () => {
    if (!this.animating) return;

    this.animating = false;

    this.airship.stop();
    this.captain.stopAnimation();
    this.navigator.stopAnimation();
    this.droid.stopAnimation();

    return this;
  };
}

let instance;

export default (args) => {
  if (!instance) instance = new ThreeSceneClass(args);

  return instance;
};
