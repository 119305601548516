import * as THREE from "three";
import { Scene, SpotLight } from "../_common/3d";
import { City, Cloud } from "../_assets/3d";

let initialized = false;
let onInit = null;
let city, cloud1, cloud2, cloud3, cloud4, cloud5, light;
let cleanupTimeout = null;
let transitioningOut = false;

const transitionIn = () => {
  Scene()
    .changeBackgroundColor({ color: new THREE.Color(0x36bcff) })
    .showHideGrid(true);
  city.reset().start().swivel(40000, -1);
  cloud1.float(0.1, 1.1);
  cloud2.float(0.1, 1.3);
  cloud3.float(0.1, 1.5);
  cloud4.float(0.1, 1.7);
  cloud5.float(0.1, 1.9);
};

const transitionOut = () => {
  if (!city || transitioningOut) return;

  transitioningOut = true;
  city.stop();
  cloud1.stop();
  cloud2.stop();
  cloud3.stop();
  cloud4.stop();
  cloud5.stop();

  cleanupTimeout = setTimeout(() => {
    // Perform resource cleanup after scene has transitioned out
    light.destroy();
    city.destroy();
    cloud1.destroy();
    cloud2.destroy();
    cloud3.destroy();
    cloud4.destroy();
    cloud5.destroy();
    Scene().remove(city);
    Scene().remove(cloud1);
    Scene().remove(cloud2);
    Scene().remove(cloud3);
    Scene().remove(cloud4);
    Scene().remove(cloud5);
    city = null;
    cloud1 = null;
    cloud2 = null;
    cloud3 = null;
    cloud4 = null;
    cloud5 = null;
    initialized = false;
    Scene().renderer.renderLists.dispose();
  }, 2000);
};

const create = () => {
  clearTimeout(cleanupTimeout);
  transitioningOut = false;

  if (initialized) return onInit && onInit();

  city = City({
    position: { x: 0, y: 100, z: 0 },
  });
  cloud1 = Cloud({
    scale: 1,
    position: { x: -3, y: 19, z: -9 },
    rotation: { y: 2.5 },
  });
  cloud2 = Cloud({
    scale: 1.1,
    position: { x: 7, y: 17, z: -5 },
    rotation: { y: 1 },
  });
  cloud3 = Cloud({
    scale: 1,
    position: { x: -3, y: 16, z: -3 },
    rotation: { y: 1 },
  });
  cloud4 = Cloud({
    scale: 0.8,
    position: { x: 4, y: 23, z: -6 },
    rotation: { y: 1 },
  });
  cloud5 = Cloud({
    scale: 1,
    position: { x: -5, y: 23, z: -8 },
    rotation: { y: 1 },
  });
  light = SpotLight({
    key: "skyLight",
    position: { x: 1, y: 33, z: -2 },
    distance: 18,
    intensity: 1.7,
    decay: 0.01,
    angle: Math.PI / 4.5,
    penumbra: 1.9,
    shadow: {
      far: 20,
    },
    target: { x: -5, y: 10, z: 4 },
  });

  Scene().add(city);
  Scene().add(cloud1);
  Scene().add(cloud2);
  Scene().add(cloud3);
  Scene().add(cloud4);
  Scene().add(cloud5);
  Scene().add(light);

  initialized = true;

  onInit && setTimeout(onInit, 200);
};

const whenReady = (func) => {
  if (!func) return;

  if (!initialized) {
    onInit = func;
    return;
  }

  func();
};

export { create, transitionIn, transitionOut, whenReady };
