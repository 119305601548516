import React from "react";
import { Industries } from "../_assets/2d/Images";
import "./styles/IndustryIcon.scss";

export default ({ icon, text }) => (
  <div className={`industry-icon ${icon}`}>
    <div
      className={`industry-graphic ${icon}`}
      style={{ backgroundImage: `url(${Industries})` }}
    />
    <span>{text}</span>
  </div>
);
