export const Routes = {
  Home: "/",
  Team: "/team-up",
  Architect: "/architect-a-lasting-solution",
  Ship: "/ship-quality-software",
  Experience: "/experience-something-breathtaking",
  Root: "/",
  More: "/more",
  Contact: "/contact-us",
  getRouteFromScene: (scene) => {
    switch (scene) {
      case Scenes.Home:
        return Routes.Home;
      case Scenes.Team:
        return Routes.Team;
      case Scenes.TeamMore:
        return `${Routes.Team}${Routes.More}`;
      case Scenes.Architect:
        return Routes.Architect;
      case Scenes.ArchitectMore:
        return `${Routes.Architect}${Routes.More}`;
      case Scenes.Ship:
        return Routes.Ship;
      case Scenes.ShipMore:
        return `${Routes.Ship}${Routes.More}`;
      case Scenes.Experience:
        return Routes.Experience;
      case Scenes.ExperienceMore:
        return `${Routes.Experience}${Routes.More}`;
      case Scenes.Contact:
        return Routes.Contact;
    }
  },
};

export const Scenes = {
  Home: "main",
  Team: "team",
  TeamMore: "team/more",
  Architect: "architect",
  ArchitectMore: "architect/more",
  Ship: "ship",
  ShipMore: "ship/more",
  Experience: "experience",
  ExperienceMore: "experience/more",
  Contact: "contact",
};

export const CameraPositions = {
  full: {
    initial: {
      position: { x: 0, y: 2, z: 90 },
      lookAt: { x: 0, y: 1, z: 0 },
    },
    landing: {
      position: { x: 0, y: 1, z: 95 },
      lookAt: { x: 0, y: 4, z: -1 },
    },
    contact: {
      position: { x: -24, y: 2.5, z: 92 },
      lookAt: { x: -25, y: -80, z: 0 },
    },
    summary: {
      Team: {
        position: { x: 1.5, y: 3, z: 6.7 },
        lookAt: { x: 0.8, y: 1.5, z: 0 },
      },
      Architect: {
        position: { x: 0.5, y: 2.1, z: 5.5 },
        lookAt: { x: 0.7, y: 1.8, z: 4 },
      },
      Ship: {
        position: { x: 0.5, y: 2.1, z: 5.5 },
        lookAt: { x: 0.7, y: 1.8, z: 4 },
      },
      Experience: {
        position: { x: 1, y: 22.9, z: 6.8 },
        lookAt: { x: 0, y: 21.7, z: 0 },
      },
    },
    detail: {
      Team: {
        position: { x: 4.4, y: 2.6, z: 4.1 },
        lookAt: { x: 3.0, y: 1.8, z: 1 },
      },
      Architect: {
        position: { x: 0.5, y: 2.5, z: 1.9 },
        lookAt: { x: 5, y: 1.3, z: -0.2 },
      },
      Ship: {
        position: { x: 1.8, y: 1.6, z: 4.0 },
        lookAt: { x: 4.0, y: 0.7, z: -1 },
      },
      Experience: {
        position: { x: 0.8, y: 21.9, z: 4.6 },
        lookAt: { x: 4, y: 20.2, z: -1.8 },
      },
    },
  },
  tablet: {
    initial: {
      position: { x: 0, y: 2, z: 90 },
      lookAt: { x: 0, y: 1, z: 0 },
    },
    landing: {
      position: { x: 0, y: 1, z: 95 },
      lookAt: { x: 0, y: 4, z: -1 },
    },
    contact: {
      position: { x: -24, y: 2.5, z: 92 },
      lookAt: { x: -25, y: -80, z: 0 },
    },
    summary: {
      Team: {
        position: { x: 1.5, y: 3, z: 7.7 },
        lookAt: { x: 1.3, y: 1.5, z: 0 },
      },
      Architect: {
        position: { x: 1.1, y: 2.1, z: 6.5 },
        lookAt: { x: 1.4, y: 1.8, z: 2 },
      },
      Ship: {
        position: { x: 1.1, y: 2.1, z: 6.5 },
        lookAt: { x: 1.4, y: 1.8, z: 2 },
      },
      Experience: {
        position: { x: 1.5, y: 22.9, z: 6.8 },
        lookAt: { x: 0.5, y: 21.7, z: 0 },
      },
    },
    detail: {
      Team: {
        position: { x: 4.4, y: 2.6, z: 4.1 },
        lookAt: { x: 3.0, y: 1.8, z: 1 },
      },
      Architect: {
        position: { x: 0.5, y: 2.5, z: 1.9 },
        lookAt: { x: 5, y: 1.3, z: -0.2 },
      },
      Ship: {
        position: { x: 1.8, y: 1.6, z: 4.0 },
        lookAt: { x: 4.0, y: 0.7, z: -1 },
      },
      Experience: {
        position: { x: 0.8, y: 21.9, z: 4.6 },
        lookAt: { x: 4, y: 20.2, z: -1.8 },
      },
    },
  },
  mobile: {
    initial: {
      position: { x: 0, y: 2, z: 90 },
      lookAt: { x: 0, y: 1, z: 0 },
    },
    landing: {
      position: { x: 0, y: 2, z: 95 },
      lookAt: { x: 0, y: 4, z: -1 },
    },
    contact: {
      position: { x: -24, y: 2.5, z: 92 },
      lookAt: { x: -25, y: -80, z: 0 },
    },
    summary: {
      Team: {
        position: { x: 0.8, y: 4, z: 9.8 },
        lookAt: { x: 3.22, y: 3.2, z: 0 },
      },
      Architect: {
        position: { x: 0.8, y: 4, z: 9.8 },
        lookAt: { x: 3.22, y: 3.2, z: 0 },
      },
      Ship: {
        position: { x: 0.8, y: 4, z: 9.8 },
        lookAt: { x: 3.22, y: 3.2, z: 0 },
      },
      Experience: {
        position: { x: 4, y: 23.9, z: 8.8 },
        lookAt: { x: 1.2, y: 23.1, z: 0 },
      },
    },
    detail: {
      Team: {
        position: { x: 2.9, y: 2.8, z: 4.8 },
        lookAt: { x: 3.0, y: 2.2, z: 0 },
      },
      Architect: {
        position: { x: -0.5, y: 2.3, z: 1.6 },
        lookAt: { x: 7, y: 0.7, z: -0.2 },
      },
      Ship: {
        position: { x: 1.5, y: 1.6, z: 5.2 },
        lookAt: { x: 3.22, y: 1.8, z: 0 },
      },
      Experience: {
        position: { x: 1.2, y: 21.9, z: 6.9 },
        lookAt: { x: 4.2, y: 21.9, z: -1 },
      },
    },
  },
};

export const ScenePositions = {
  full: {
    initial: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { y: 0 },
    },
    landing: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { y: 0 },
    },
    contact: {
      position: { x: 0, y: 2.3, z: 0 },
      rotation: { y: 0 },
    },
    summary: {
      Team: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Architect: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Ship: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Experience: {
        position: { x: 2, y: 1.2, z: 2 },
        rotation: { y: 0 },
      },
    },
    detail: {
      Team: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: -2.6 },
      },
      Architect: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Ship: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Experience: {
        position: { x: 2, y: 1.2, z: 2 },
        rotation: { y: -0.5 },
      },
    },
  },
  tablet: {
    initial: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { y: 0 },
    },
    landing: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { y: 0 },
    },
    contact: {
      position: { x: -0.2, y: 2.3, z: 0 },
      rotation: { y: 0 },
    },
    summary: {
      Team: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Architect: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Ship: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Experience: {
        position: { x: 2, y: 1.2, z: 2 },
        rotation: { y: 0 },
      },
    },
    detail: {
      Team: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: -2.6 },
      },
      Architect: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Ship: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Experience: {
        position: { x: 2, y: 1.2, z: 2 },
        rotation: { y: -0.5 },
      },
    },
  },
  mobile: {
    initial: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { y: 0 },
    },
    landing: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { y: 0 },
    },
    contact: {
      position: { x: -0.2, y: 2.1, z: 0 },
      rotation: { y: 0 },
    },
    summary: {
      Team: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Architect: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Ship: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Experience: {
        position: { x: 2, y: 1.2, z: 2 },
        rotation: { y: 0 },
      },
    },
    detail: {
      Team: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: -3.2 },
      },
      Architect: {
        position: { x: 3, y: 1.2, z: 1 },
        rotation: { y: 0 },
      },
      Ship: {
        position: { x: 3, y: 1.6, z: 1 },
        rotation: { y: 0 },
      },
      Experience: {
        position: { x: 2.7, y: 1.2, z: 2.8 },
        rotation: { y: -0.4 },
      },
    },
  },
};
