import Object3D from "./Object3D";
import * as THREE from "three";

class AmbientLightClass extends Object3D {
  constructor(args) {
    super();

    const l = new THREE.AmbientLight(
      args?.color ?? 0x40400,
      args?.intensity ?? 0.7
    );

    this.init(l, { ...args, shadow: { cast: false, receive: false } });
  }
}

export default (args) => new AmbientLightClass(args);
