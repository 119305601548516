import { TextureLoader, Skeleton } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as Glbs from "../_assets/3d/glb";
import * as Textures from "../_assets/2d/Textures";

const types = {
  texture: "texture",
  model: "glb",
};

const assetLibrary = [
  {
    key: "Images.WhiteGradient",
    value: Textures.WhiteGradient,
    type: types.texture,
  },
  {
    key: "Images.GridTexture",
    value: Textures.GridTexture,
    type: types.texture,
  },
  {
    key: "Images.SkyCityMesh",
    value: Textures.SkyCityMesh,
    type: types.texture,
  },
  {
    key: "Images.WoodenTexture",
    value: Textures.WoodenTexture,
    type: types.texture,
  },
  {
    key: "Images.WorldMapTexture",
    value: Textures.WorldMapTexture,
    type: types.texture,
  },
  {
    key: "Models.Warehouse",
    value: Glbs.Warehouse,
    type: types.model,
  },
  {
    key: "Models.City",
    value: Glbs.City,
    type: types.model,
  },
  {
    key: "Models.Dock",
    value: Glbs.Docks,
    type: types.model,
  },
  {
    key: "Models.Tavern",
    value: Glbs.Tavern,
    type: types.model,
  },
  {
    key: "Models.Captain.LOD2",
    value: Glbs.CaptainLod2,
    type: types.model,
  },
  {
    key: "Models.Droid.LOD2",
    value: Glbs.DroidLod2,
    type: types.model,
  },
  {
    key: "Models.Navigator.LOD2",
    value: Glbs.NavigatorLod2,
    type: types.model,
  },
  {
    key: "Models.Airship",
    value: Glbs.Airship,
    type: types.model,
  },
  {
    key: "Models.Cloud",
    value: Glbs.Cloud,
    type: types.model,
  },
  {
    key: "Models.ThumbTack",
    value: Glbs.ThumbTack,
    type: types.model,
  },
];
const loadedAssets = {};

export const loadAssets = (callback, progress) => {
  const modelLoader = new GLTFLoader();
  const textureLoader = new TextureLoader();
  let loadedCount = 0;
  let totalCount = assetLibrary.length;

  progress && progress(loadedCount, totalCount);

  Promise.all(
    assetLibrary.map(
      (a) =>
        new Promise((resolve) => {
          if (a.type === types.model)
            modelLoader
              .loadAsync(a.value, (p) => {})
              .then((gltf) => {
                progress && progress(++loadedCount, totalCount);
                return resolve({ key: a.key, gltf });
              });
          else
            textureLoader
              .loadAsync(a.value.src, (p) => {})
              .then((texture) => {
                progress && progress(++loadedCount, totalCount);
                return resolve({ key: a.key, texture });
              });
        })
    )
  )
    .then((assets) => {
      assets.map((a) => {
        if (a.gltf && a.gltf.scene) {
          a.gltf.scene.traverse((child) => {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;

              if (child.material.map) child.material.map.anistropy = 16;
            }
          });
        }

        loadedAssets[a.key] = a.gltf ?? a.texture;
        return a;
      });
      callback();
    })
    .catch((err) => console.error(err));
};

export const getAsset = (key, cloneAsset) => {
  const gltf = loadedAssets[key];

  if (!cloneAsset) return gltf;

  const clone = {
    animations: gltf.animations,
    scene: gltf.scene.clone(true),
  };

  const skinnedMeshes = {};

  gltf.scene.traverse((node) => {
    if (node.isSkinnedMesh) {
      skinnedMeshes[node.name] = node;
    }
  });

  const cloneBones = {};
  const cloneSkinnedMeshes = {};

  clone.scene.traverse((node) => {
    if (node.isBone) {
      cloneBones[node.name] = node;
    }

    if (node.isSkinnedMesh) {
      cloneSkinnedMeshes[node.name] = node;
    }
  });

  for (let name in skinnedMeshes) {
    const skinnedMesh = skinnedMeshes[name];
    const skeleton = skinnedMesh.skeleton;
    const cloneSkinnedMesh = cloneSkinnedMeshes[name];

    const orderedCloneBones = [];

    for (let i = 0; i < skeleton.bones.length; ++i) {
      const cloneBone = cloneBones[skeleton.bones[i].name];
      orderedCloneBones.push(cloneBone);
    }

    cloneSkinnedMesh.bind(
      new Skeleton(orderedCloneBones, skeleton.boneInverses),
      cloneSkinnedMesh.matrixWorld
    );
  }

  return clone;
};
