import React, { useEffect } from "react";
import * as ArchitectScene from "./ArchitectScene";

export default (props) => {
  const { children } = props;

  // onMount && onMount(Scenes.Architect);

  useEffect(() => {
    ArchitectScene.create();
    ArchitectScene.transitionIn();

    return ArchitectScene.transitionOut;
  }, []);

  return <>{children}</>;
};
