import * as THREE from "three";
import Object3D from "./Object3D";
import Scene from "./Scene";
import Particles from "./Particles";
import gsap, { Power2 } from "gsap";
import SpotLight from "./SpotLight";

class PlatformClass extends Object3D {
  constructor(args) {
    super(args);

    this.onOut = args?.onOut;
    this.onSpin = args?.onSpin;
    this.onDestroy = args?.onDestroy;

    const group = new THREE.Group();

    const geo = new THREE.BoxGeometry(
      args?.width ?? 3,
      args?.height ?? 0.1,
      args?.depth ?? 3
    );
    const mat = new THREE.MeshStandardMaterial({
      color: args?.color ?? 0x888888,
    });
    const mesh = new THREE.Mesh(geo, mat);
    mesh.castShadow = args?.shadow?.cast;
    mesh.receiveShadow = args?.shadow?.receive;
    group.add(mesh);

    const light = SpotLight({
      key: "platformLight",
      color: new THREE.Color(0x999999),
      position: { x: -1, y: 6, z: 3 },
      distance: 18,
      intensity: 5.0,
      angle: Math.PI / 6,
      decay: 2,
      target: { x: -1, y: 0, z: -2 },
    });
    group.add(light.object3d);
    group.add(light.target);

    if (args?.children?.length) {
      this.children = args.children;
      args.children.forEach((c) => group.add(c.object3d));
    }

    group.position.x = args?.position?.x ?? 0;
    group.position.y = args?.position?.y ?? 0;
    group.position.z = args?.position?.z ?? 0;

    group.rotation.x = args?.rotation?.x ?? 0;
    group.rotation.y = args?.rotation?.y ?? 0;
    group.rotation.z = args?.rotation?.z ?? 0;

    this.init(group, args);
  }

  spin = (callback) => {
    this.onSpin && this.onSpin();

    const current = { y: this.object3d.rotation.y ?? 0 };

    gsap.to(this.object3d.rotation, {
      y: current.y + Math.PI * 2,
      ease: Power2.easeInOut,
      duration: 0.8,
      onComplete: callback ?? function () {},
    });
  };

  out = () => {
    this.onOut && this.onOut();
  };

  update = (rotation) => {
    this.object3d.rotation.x = rotation.x ?? this.object3d.rotation.x;
    this.object3d.rotation.y = rotation.y ?? this.object3d.rotation.y;
    this.object3d.rotation.z = rotation.z ?? this.object3d.rotation.z;
  };

  destroy() {
    this.onDestroy && this.onDestroy();
    super.destroy();
    Scene().remove(_singleton);
    _singleton = null;
  }
}

let _singleton;

/**
 * @returns {PlatformClass} The platform.
 */
export default (args) => {
  if (!_singleton) {
    const dust = Particles();
    _singleton = new PlatformClass({
      ...args,
      position: { y: -0.86 },
      rotation: { y: Math.PI / 6 },
      shadow: { cast: true, receive: true },
      onSpin: () => {
        dust.start();
      },
      onOut: () => {
        dust.stop();
      },
      onDestroy: () => {
        dust.destroy();
      },
    });
    Scene().add(_singleton);
    _singleton.addChildren([dust]);
  }

  _singleton.addChildren(args?.children);

  return _singleton;
};
