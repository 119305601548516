import { useEffect } from "react";
import { StateStore } from ".";
import { v4 } from "uuid";

/**
 * A utility that subscribes and unsubscribes a functional component to the specified state topics.
 * @param {{topic: String, eventHandler: Function}[]} subscriptions An array of subscriptions to specific topics
 */
export const useStateSubscriber = (subscriptions) => {
  useEffect(
    (_) => {
      const unsubHandlers = [];

      subscriptions.forEach((s) => {
        s.uuid = v4();
        StateStore.subscribe(s.topic, s.uuid, s.eventHandler);
        unsubHandlers.push((_) => StateStore.unsubscribe(s.topic, s.uuid));
      });

      return (_) => unsubHandlers.map((h) => h());
    },
    [subscriptions]
  );
};
