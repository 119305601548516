import React, { useEffect } from "react";
import * as ShipScene from "./ShipScene";

export default (props) => {
  const { children } = props;

  useEffect(() => {
    ShipScene.create();
    ShipScene.transitionIn();

    return ShipScene.transitionOut;
  }, []);

  return <>{children}</>;
};
