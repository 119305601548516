import React from "react";
import { Chevron } from "../_assets/2d/Icons";
import "./styles/ScrollDownIndicator.scss";

export default (props) => {
  return (
    <div {...props} className="scroll-down-indicator">
      <Chevron className="chevron top" />
      <Chevron className="chevron middle" />
      <Chevron className="chevron bottom" />
    </div>
  );
};
