import React, { useEffect, useState } from "react";
import {
  DetailContent,
  Page,
  ReadMoreButton,
  Scenes,
  ScrollDownIndicator,
  Section,
} from "../_common";
import "./styles/Main.scss";
import { Platform, Scene } from "../_common/3d";
import { CameraPositions, Routes, ScenePositions } from "../_common/Constants";
import * as MainScene from "./MainScene";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { responsiveHeightSubtraction } from "../utils";
import { TeamPose } from "../_assets/2d/Images";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const testimonials = [
  {
    person: "John Garner",
    title: "Founder & CEO",
    client: "Card Curator",
    message:
      "We at Card Curator have been working with type{DEV} for multiple years now and are very happy with their service and quality of work. They provided us an amazing lead architect who has really helped us build our vision.",
  },
  {
    person: "Minal Kooverjee",
    title: "Executive Head: Software Development",
    client: "Brolink",
    message: "Redefining customer experience.",
  },
  {
    person: "Shanai Misra",
    title: "Development & Operations Manager",
    client: "Bidvest Data Gauteng",
    message:
      "We appreciate their attention to detail and creative approach to bringing our exact needs and requirements to life!",
  },
];

export default (props) => {
  const { onMount, screenSize } = props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
  }, []);

  useEffect(() => {
    Platform().out();
    MainScene.create();
    MainScene.transitionIn();

    Scene().moveScenePosition(ScenePositions[screenSize].landing);
    Scene().moveCameraPosition(CameraPositions[screenSize].landing);

    onMount && onMount(Scenes.Home);
  }, [screenSize, onMount]);

  if (!loaded) return <></>;

  return (
    <Page
      id="main"
      className="details"
      footer="large"
      style={{ marginTop: height }}
    >
      <div className="master-software-crafters">
        <span>
          <em>Master</em>
        </span>
        <span>
          <em>Software</em>
        </span>
        <span>
          <em>Crafters</em>
        </span>
      </div>
      <ScrollDownIndicator />
      <DetailContent>
        <Section
          heading="We Build Digital Experiences"
          className="team-pose"
          graphic={<div style={{ backgroundImage: `url(${TeamPose})` }} />}
        >
          <p>
            We understand software. Whether that means creating, fixing or
            advising, we understand it all. We don't pigeon-hole ourselves to
            specific technologies, stacks, platforms, industries or
            methodologies and approach each opportunity with a fresh, creative
            and professional mindset.
          </p>
          <p>
            We believe in forging a mastery in everything we do and understand
            the importance of engineering efficiency, technical proficiency and
            artistic splendour. We regard software as our passionate craft and
            strive to achieve an unparalleled level of mastery while providing
            our clients with the premium service they deserve.
          </p>
          <ReadMoreButton location={Routes.Team}>
            Interested? Journey with us.
          </ReadMoreButton>
        </Section>
        <Section bodyClasses="testimonials">
          <Swiper pagination autoplay={{ delay: 7000 }} grabCursor>
            {testimonials.map((t, i) => (
              <SwiperSlide className="testimonial" key={`testimonial-${i}`}>
                <blockquote>&#8220;{t.message}&#8221;</blockquote>
                <span className="person">{t.person}</span>
                <span className="role">{t.title} - </span>
                <span className="client">{t.client}</span>
              </SwiperSlide>
            ))}
          </Swiper>
        </Section>
        <Section
          className="our-story"
          heading="Our Story"
          graphic={
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/26iGR2Vg6KU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            />
          }
        >
          <p>
            Founded in 2014 as a five-person company, we have evolved into a
            multinational, software development house. Though we have grown, at
            our core, we are Master Software Crafters in the tradition of the
            guilds of old. We have since built, fixed, expanded and consulted on
            a wide range of digital experiences spanning industries such as
            insurance technology (insurtech), financial technology (fintech),
            telecommunications, medico-legal, supply chain and sporting
            sciences.
          </p>
          <p>
            Perfection is not a virtue, it’s a mindset. We instill projects with
            quality, finesse and reverence – elevating each project to a piece
            of art. Craftmanship accentuates attitude and demands years of
            acquiring knowledge and experience, to refine the aptitude for
            delivering bespoke cross-industry solutions. Emphasis is placed on
            skills and how we transform the unknown into the known, with
            artistry.
          </p>
        </Section>
      </DetailContent>
    </Page>
  );
};
