import * as THREE from "three";

export const applyIdleAnimation = (scene, animations, timeScale) => {
  let mixer;

  if (animations.length) {
    mixer = new THREE.AnimationMixer(scene);
    const clip = mixer.clipAction(animations[0]);
    clip.timeScale = timeScale ?? 1;
    clip.play();
  }

  return mixer;
};

export const fixMaterials = (scene) => {
  scene.traverse((child) => {
    if (child.isMesh) child.material.metalness = 0;
  });
};

export const destroyObject = (object, debug) => {
  if (!(object instanceof THREE.Object3D)) return;

  debug && console.log("destroyObject", object);

  object.traverse((o) => {
    if (o.geometry) {
      debug && console.log("disposing geometry", o.geometry);
      o.geometry.dispose();
    }

    if (o.material) {
      debug && console.log("disposing material", o.material);

      if (o.material instanceof Array)
        o.material.forEach((material) => {
          material.dispose();
        });
      else {
        o.material.dispose();
      }
    }
  });

  if (object.parent) object.parent.remove(object);
};
