import React, { useEffect, useState } from "react";
import {
  responsiveHeightSubtraction,
  StateStore,
  useStateSubscriber,
} from "../utils";
import {
  EmailIcon,
  LocationIcon,
  PersonIcon,
  PhoneIcon,
  WhatsAppIcon,
} from "../_assets/2d/Icons";
import { Page, Scenes } from "../_common";
import { Platform, Scene } from "../_common/3d";
import { CameraPositions, ScenePositions } from "../_common/Constants";
import * as ContactScene from "./ContactScene";
import "./styles/Contact.scss";

export default (props) => {
  const { onMount, screenSize } = props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);
  const [selected, setSelected] = useState("Centurion");

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
  }, []);

  useEffect(() => {
    Platform().out();
    ContactScene.create();
    ContactScene.transitionIn();

    Scene().moveScenePosition(ScenePositions[screenSize].contact);
    Scene().moveCameraPosition(CameraPositions[screenSize].contact);

    onMount && onMount(Scenes.Contact);

    StateStore.publish("location-details", "Centurion");
  }, [screenSize, onMount]);

  const onTabChange = (tab) => {
    setSelected(tab);
    StateStore.publish("location-details", tab);
  };

  useStateSubscriber([
    {
      topic: "location-details",
      eventHandler: (tab) => setSelected(tab),
    },
  ]);

  if (!loaded) return <></>;

  return (
    <Page id="contact" footer="large" style={{ paddingTop: height }}>
      <div className="contact-box">
        <div className="tabs">
          <span
            className={`tab${selected === "Centurion" ? " selected" : ""}`}
            onClick={() => onTabChange("Centurion")}
          >
            Centurion
          </span>
          <span
            className={`tab${selected === "Cape Town" ? " selected" : ""}`}
            onClick={() => onTabChange("Cape Town")}
          >
            Cape Town
          </span>
          <span
            className={`tab${selected === "Vienna" ? " selected" : ""}`}
            onClick={() => onTabChange("Vienna")}
          >
            Vienna
          </span>
        </div>
        <div className="details">
          {selected === "Centurion" && <CenturionDetails />}
          {selected === "Cape Town" && <CapeTownDetails />}
          {selected === "Vienna" && <ViennaDetails />}
        </div>
      </div>
    </Page>
  );
};

const CenturionDetails = () => (
  <>
    <p className="contact-line contact-person">
      <PersonIcon />
      <span>Mercialé Swanepoel</span>
    </p>
    <p className="contact-line">
      <WhatsAppIcon />
      <a
        href="https://wa.me/27834644775?text=I'm%20interested%20in%20{type}DEV's%20Centurion%20services.%20Would%20you%20mind%20getting%20in%20contact%20with%20me%3F"
        target="_black"
        rel="noopener noreferrer"
        title="Centurion Telephone Number"
      >
        Send a WhatsApp
      </a>
    </p>
    <p className="contact-line">
      <EmailIcon />
      <a
        href="mailto:centurion@typedev.io"
        target="_black"
        rel="noopener noreferrer"
        title="Centurion Email Address"
      >
        centurion@typedev.io
      </a>
    </p>
    <p className="contact-line">
      <PhoneIcon />
      <a
        href="tel:+27834644775"
        target="_black"
        rel="noopener noreferrer"
        title="Centurion Telephone Number"
      >
        (+27) 083-464-4775
      </a>
    </p>
    <p className="contact-line">
      <LocationIcon />
      <span>
        Unit 29, Norma Jean Square
        <br />
        244 Jean Avenue
        <br />
        Die Hoewes
        <br />
        Centurion
        <br />
        South Africa
        <a
          href="https://goo.gl/maps/t8VyqEwnXmVoar4e7"
          target="_blank"
          rel="noopener noreferrer"
        >
          View in Google Maps
        </a>
      </span>
    </p>
  </>
);

const CapeTownDetails = () => (
  <>
    <p className="contact-line contact-person">
      <PersonIcon />
      <span>Zane Masson</span>
    </p>
    <p className="contact-line">
      <WhatsAppIcon />
      <a
        href="https://wa.me/27798982071?text=I'm%20interested%20in%20{type}DEV's%20Cape%20Town%20services.%20Would%20you%20mind%20getting%20in%20contact%20with%20me%3F"
        target="_black"
        rel="noopener noreferrer"
        title="Centurion Telephone Number"
      >
        Send a WhatsApp
      </a>
    </p>
    <p className="contact-line">
      <EmailIcon />
      <a
        href="mailto:capetown@typedev.io"
        target="_black"
        rel="noopener noreferrer"
        title="Cape Town Email Address"
      >
        capetown@typedev.io
      </a>
    </p>
    <p className="contact-line">
      <PhoneIcon />
      <a
        href="tel:+27798982071"
        target="_black"
        rel="noopener noreferrer"
        title="Centurion Telephone Number"
      >
        (+27) 079-898-2071
      </a>
    </p>
    <p className="contact-line">
      <LocationIcon />
      <span>
        First Floor, Carbon House
        <br />
        Springfield Office Park
        <br />
        109 Jip de Jager Drive
        <br />
        De Bron
        <br />
        Cape Town
        <br />
        South Africa
        <br />
        <a
          href="https://goo.gl/maps/urSP8YDTqjcwRWxw7"
          target="_blank"
          rel="noopener noreferrer"
        >
          View in Google Maps
        </a>
      </span>
    </p>
  </>
);

const ViennaDetails = () => (
  <>
    <p className="contact-line contact-person">
      <PersonIcon />
      <span>Eben Nel</span>
    </p>
    <p className="contact-line">
      <EmailIcon />
      <a
        href="mailto:vienna@typedev.io"
        target="_black"
        rel="noopener noreferrer"
        title="Vienna Email Address"
      >
        vienna@typedev.io
      </a>
    </p>
    <p className="contact-line">
      <PhoneIcon />
      <a
        //href="https://wa.me/436603479097?text=I'm%20interested%20in%20{type}DEV's%20Vienna%20services.%20Would%20you%20mind%20getting%20in%20contact%20with%20me%3F"
        href="tel:+436603479097"
        target="_black"
        rel="noopener noreferrer"
        title="Vienna Telephone Number"
      >
        (+43) 660-347-9097
      </a>
    </p>
    <p className="contact-line">
      <LocationIcon />
      <span>
        Liechtensteinstraße 111-115
        <br />
        1090 Wien
        <br />
        Austria
        <br />
        <a
          href="https://goo.gl/maps/19S9adnGSXXWidwz8"
          target="_blank"
          rel="noopener noreferrer"
        >
          View in Google Maps
        </a>
      </span>
    </p>
  </>
);
