import { getAsset } from "../../utils/AssetLoader";
import TransitionableMesh from "../../_common/3d/TransitionableMesh";
import gsap, { Power1 } from "gsap";
import { Color, MeshPhongMaterial } from "three";

class CloudClass extends TransitionableMesh {
  constructor(args) {
    super(args);

    let { scene } = getAsset("Models.Cloud");

    const scale = args?.scale;

    scene = scene.clone();
    scene.traverse((child) => {
      if (!child.isMesh) return;

      child.material = new MeshPhongMaterial({
        emissive: new Color(0xffffff),
        emissiveIntensity: 0.2,
        shininess: 100,
        specular: new Color(0x000000),
      });
    });

    scene.scale.x = scale;
    scene.scale.y = scale;
    scene.scale.z = scale;
    scene.rotation.x = args?.rotation?.x ?? 0;
    scene.rotation.y = args?.rotation?.y ?? 0;
    scene.rotation.z = args?.rotation?.z ?? 0;
    scene.position.x = args?.position?.x ?? 0;
    scene.position.y = args?.position?.y ?? 0;
    scene.position.z = args?.position?.z ?? 0;

    this.init(scene, { shadow: { cast: false } });
  }

  float = (amount, duration) => {
    if (this.floating) return this;

    const bob = { y: this.object3d.position.y };

    this.bobTween = gsap.to(this.object3d.position, {
      y: bob.y - (amount ?? 0.03),
      duration: duration ?? 1.1,
      repeat: -1,
      yoyo: true,
      ease: Power1.easeInOut,
    });

    this.floating = true;

    return this;
  };

  stop = () => {
    this.bobTween && this.bobTween.kill();
    this.floating = false;
  };
}

export default (args) => new CloudClass(args);
