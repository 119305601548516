import { getAsset } from "../../utils/AssetLoader";
import TransitionableMesh from "../../_common/3d/TransitionableMesh";
import { applyIdleAnimation, fixMaterials } from "../../utils/AssetUtils";

class NavigatorClass extends TransitionableMesh {
  constructor(args) {
    super(args);

    let { scene, animations } = getAsset(
      "Models.Navigator.LOD" + args.lod,
      true
    );

    fixMaterials(scene);

    const scale = args?.scale ?? 5.7;

    scene.scale.x = scale;
    scene.scale.y = scale;
    scene.scale.z = scale;
    scene.rotation.x = args?.rotation?.x ?? 0;
    scene.rotation.y = args?.rotation?.y ?? 0;
    scene.rotation.z = args?.rotation?.z ?? 0;
    scene.position.x = args?.position?.x ?? 0;
    scene.position.y = args?.position?.y ?? 0;
    scene.position.z = args?.position?.z ?? 0;

    this.init(scene, null, applyIdleAnimation(scene, animations, 0.25));
  }
}

export default (args) => new NavigatorClass(args);
