import Object3D from "./Object3D";
import * as THREE from "three";

class PointLightClass extends Object3D {
  constructor(args) {
    super();

    const l = new THREE.PointLight(
      args?.color ?? 0xffffff,
      args?.intensity ?? 0.4,
      args?.distance ?? 20,
      args?.decay ?? 1
    );

    l.position.set(
      args?.position?.x ?? 0,
      args?.position?.y ?? 0,
      args?.position?.z ?? 0
    );
    l.shadow.mapSize.width = args?.shadow?.resolution ?? 2048;
    l.shadow.mapSize.height = args?.shadow?.resolution ?? 2048;
    l.shadow.camera.near = args?.shadow?.near ?? 0.5;
    l.shadow.camera.far = args?.shadow?.far ?? 500;

    this.init(l, {
      ...args,
      shadow: { cast: args?.shadow?.cast ?? true, receive: false },
    });
  }
}

export default ({ key, color, intensity, distance, decay, position, shadow }) =>
  new PointLightClass({
    key,
    color,
    intensity,
    distance,
    decay,
    position,
    shadow,
  });
