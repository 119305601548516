import * as THREE from "three";
import { Platform, PointLight, Scene } from "../_common/3d";
import { Tavern, Captain, Droid, Navigator } from "../_assets/3d";
import { StateStore } from "../utils";

let initialized = false;
let onInit = null;
let tavern, captain, droid, navigator, light;
let cleanupTimeout = null;
const captainPosition = { x: -1.0, y: 0.37, z: 0.9 },
  captainRotation = { x: 0, y: -1.1, z: 0 },
  navigatorPosition = { x: -0.15, y: 1.655, z: 0.65 },
  navigatorRotation = { x: 0, y: -0.5, z: 0 },
  droidPosition = { x: 0.8, y: 1.51, z: -1.1 },
  droidRotation = { x: 0, y: 2.7, z: 0 },
  actorScale = 5.8;

const transitionIn = () => {
  Platform().spin();
  Scene()
    .changeBackgroundColor({ color: new THREE.Color(0x54d1ff) })
    .showHideGrid(true);

  tavern.animate().jumpIn(null, 7);
  captain.reset().popIn().startAnimation();
  droid.reset().popIn().startAnimation();
  navigator.reset().popIn().startAnimation();
};

const transitionOut = () => {
  light.hide();
  tavern.stop().sinkOut(null, 4);
  captain.sinkOut(null, 4).stopAnimation();
  droid.sinkOut(null, 4).stopAnimation();
  navigator.sinkOut(null, 4).stopAnimation();

  cleanupTimeout = setTimeout(() => {
    // Perform resource cleanup after scene has transitioned out
    Platform().removeChildren([tavern, captain, droid, navigator, light]);
    tavern = null;
    captain = null;
    droid = null;
    navigator = null;
    light = null;

    if (!/team|architect|ship/.test(StateStore.retrieve("page")))
      Platform().destroy();

    initialized = false;
    Scene().renderer.renderLists.dispose();
  }, 2000);
};

const create = () => {
  clearTimeout(cleanupTimeout);

  if (initialized) return onInit && onInit();

  tavern = Tavern({
    position: { x: 0, y: 0, z: 0 },
  });
  captain = Captain({
    delay: 1200,
    lod: 2,
    position: captainPosition,
    rotation: captainRotation,
    scale: actorScale,
  });
  droid = Droid({
    delay: 900,
    lod: 2,
    position: droidPosition,
    rotation: droidRotation,
    scale: actorScale,
  });
  navigator = Navigator({
    delay: 900,
    lod: 2,
    position: navigatorPosition,
    rotation: navigatorRotation,
    scale: actorScale,
  });
  light = PointLight({
    position: { x: 0.3, y: 1.7, z: -1.6 },
    distance: 1.2,
    intensity: 5.5,
    decay: 0.5,
  });

  tavern.hide();
  captain.hide();
  droid.hide();
  navigator.hide();

  Platform({
    children: [tavern, captain, droid, navigator, light],
  });

  initialized = true;

  onInit && setTimeout(onInit, 200);
};

const whenReady = (func) => {
  if (!func) return;

  if (!initialized) {
    onInit = func;
    return;
  }

  func();
};

export { create, transitionIn, transitionOut, whenReady };
