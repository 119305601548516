import Object3D from "./Object3D";
import * as THREE from "three";
import gsap, { Linear } from "gsap";

class HemiLightClass extends Object3D {
  constructor(args) {
    super(args);

    const l = new THREE.HemisphereLight(
      args?.skyColor ?? 0x40400,
      args?.groundColor ?? 0x40400,
      args?.intensity ?? 0.7
    );

    this.intensity = l.intensity;

    this.init(l, { ...args, shadow: { cast: false, receive: false } });
  }

  fadeOut = () => {
    gsap.to(this.object3d, {
      intensity: 0,
      ease: Linear.easeNone,
    });
  };

  fadeIn = () => {
    gsap.to(this.object3d, {
      intensity: this.intensity,
      ease: Linear.easeNone,
    });
  };
}

export default (args) => new HemiLightClass(args);
