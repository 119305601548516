import React, { useEffect, useState } from "react";
import { responsiveHeightSubtraction } from "../utils";
import {
  Page,
  SummaryHeading,
  SummaryDescription,
  ReadMoreButton,
  Routes,
} from "../_common";
import { Scene } from "../_common/3d";
import { CameraPositions, ScenePositions, Scenes } from "../_common/Constants";
import * as ShipScene from "./ShipScene";

export default (props) => {
  const { onMount, screenSize } = props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
  }, []);

  useEffect(() => {
    ShipScene.whenReady(() => {
      Scene().moveScenePosition(ScenePositions[screenSize].summary.Ship);
      Scene().moveCameraPosition(CameraPositions[screenSize].summary.Ship);
      onMount && onMount(Scenes.Ship);
    });
  }, [onMount, screenSize]);

  if (!loaded) return <></>;

  return (
    <Page
      id="ship"
      className="summary"
      footer="large"
      style={{ paddingTop: height }}
    >
      <div className="summary-content">
        <SummaryHeading main="Ship" rest="Quality Software" />
        <SummaryDescription>
          Having the right tools at your disposal isn't the only part of the
          software equation. We're quality focused with a razor-sharp eye
          for detail to ensure you get the best possible project delivered.
        </SummaryDescription>
        <ReadMoreButton location={`${Routes.Ship}${Routes.More}`} />
      </div>
    </Page>
  );
};
