import React, { useEffect, useState } from "react";
import { Routes } from ".";
import * as Icons from "../_assets/2d/Icons";
import "./styles/Header.scss";
import IconLink from "./IconLink";
import { useStateSubscriber } from "../utils";
import { Scenes } from "./Constants";
import QualitySwitcher from "./QualitySwitcher";
import {
  MenuArchitect,
  MenuExperience,
  MenuGlobe,
  MenuShip,
  MenuTeam,
} from "../_assets/2d/Images";

export default () => {
  const [bgColor, setBgColor] = useState("black");
  const [activeTab, setActiveTab] = useState("");
  const [small, setSmall] = useState(false);

  useStateSubscriber([
    {
      topic: "page",
      eventHandler: (p) => {
        let col = "black";
        let activeTab = "";

        switch (p) {
          case Scenes.Home:
            col = "black";
            activeTab = Scenes.Home;
            break;
          case Scenes.Team:
          case Scenes.TeamMore:
            col = "cyan";
            activeTab = Scenes.Team;
            break;
          case Scenes.Architect:
          case Scenes.ArchitectMore:
            col = "mint";
            activeTab = Scenes.Architect;
            break;
          case Scenes.Ship:
          case Scenes.ShipMore:
            col = "copper";
            activeTab = Scenes.Ship;
            break;
          case Scenes.Experience:
          case Scenes.ExperienceMore:
            col = "blue";
            activeTab = Scenes.Experience;
            break;
          case Scenes.Contact:
            col = "black";
            activeTab = Scenes.Contact;
            break;
          default:
            col = "black";
            activeTab = Scenes.Home;
        }

        setBgColor(col);
        setActiveTab(activeTab);
      },
    },
  ]);

  useEffect(() => {
    window.onscroll = (e) => {
      setSmall(window.scrollY > 50);
    };
  }, []);

  const classes = [bgColor];

  if (small) classes.push("scrolled");

  return (
    <>
      <header id="header" className={classes.join(" ")}>
        <span className="header-line" />
        <IconLink
          link={Routes.Home}
          icon={<Icons.LogoIcon />}
          className={`logo no-text${
            activeTab === Scenes.Home ? " active" : ""
          }`}
        />
        <QualitySwitcher />
      </header>
      <header id="header-nav" className={classes.join(" ")}>
        <IconLink
          link={Routes.Team}
          icon={<MenuIcon icon={MenuTeam} className="team" />}
          text={"Team"}
          className={`text team-up${
            activeTab === Scenes.Team ? " active" : ""
          }`}
        />
        <IconLink
          link={Routes.Architect}
          icon={<MenuIcon icon={MenuArchitect} className="architect" />}
          text={"Architect"}
          className={`text architect${
            activeTab === Scenes.Architect ? " active" : ""
          }`}
        />
        <IconLink
          link={Routes.Ship}
          icon={<MenuIcon icon={MenuShip} className="ship" />}
          text={"Ship"}
          className={`text ship${activeTab === Scenes.Ship ? " active" : ""}`}
        />
        <IconLink
          link={Routes.Experience}
          icon={<MenuIcon icon={MenuExperience} className="experience" />}
          text={"Experience"}
          className={`text experience${
            activeTab === Scenes.Experience ? " active" : ""
          }`}
        />
        <IconLink
          link={Routes.Contact}
          text={"Contact"}
          icon={<MenuIcon icon={MenuGlobe} className="contact" />}
          className={`text contact${
            activeTab === Scenes.Contact ? " active" : ""
          }`}
        />
      </header>
    </>
  );
};

const MenuIcon = ({ icon, className }) => (
  <span className={`menu-icon${className ? ` ${className}` : ""}`}>
    <i style={{ backgroundImage: `url(${icon})` }} />
  </span>
);
