import { getAsset } from "../../utils/AssetLoader";
import TransitionableMesh from "../../_common/3d/TransitionableMesh";
import gsap, { Power1 } from "gsap";

class WarehouseClass extends TransitionableMesh {
  constructor(args) {
    super(args);

    const { scene } = getAsset("Models.Warehouse");
    const scale = 5.76;
    const ctx = this;

    scene.scale.x = scale;
    scene.scale.y = scale;
    scene.scale.z = scale;
    scene.rotation.y = Math.PI;
    scene.position.y = 0.05;
    scene.position.x = 0;
    scene.position.z = 0;

    scene.traverse((child) => {
      if (
        child.isMesh &&
        child.material.map &&
        /water/.test(child.material.name)
      ) {
        child.material.transparent = true;
        child.material.opacity = 0.8;
        child.material.metalness = 0.0;
        child.material.roughness = 0.0;

        ctx.water = child;
      } else if (child.isMesh && /crane1/i.test(child.name)) {
        ctx.crane1 = child;
      } else if (child.isMesh && /crane2/i.test(child.name)) {
        ctx.crane2 = child;
      }
    });

    this.init(scene);
  }

  animate = () => {
    if (this.animating) return this;

    this.water.position.y = 0;

    this.waterFloatTween = gsap.to(this.water.position, {
      y: this.water.position.y - 0.002,
      duration: 1.1,
      repeat: -1,
      yoyo: true,
      ease: Power1.easeInOut,
    });

    this.crane1.rotation.y = 0;

    this.crane1RotationTween = gsap.to(this.crane1.rotation, {
      y: this.crane1.rotation.y - 1.0,
      delay: 4,
      duration: 2,
      ease: Power1.easeInOut,
      yoyo: true,
      repeat: -1,
      repeatDelay: 6,
    });

    this.crane2.rotation.y = 0;

    this.crane2RotationTween = gsap.to(this.crane2.rotation, {
      y: this.crane2.rotation.y + 2.0,
      delay: 3,
      duration: 7,
      ease: Power1.easeInOut,
      yoyo: true,
      repeat: -1,
      repeatDelay: 5,
    });

    this.animating = true;

    return this;
  };

  stop = () => {
    this.waterFloatTween && this.waterFloatTween.kill();
    this.crane1RotationTween && this.crane1RotationTween.kill();
    this.crane2RotationTween && this.crane2RotationTween.kill();
    this.animating = false;

    return this;
  };
}

let instance;

export default (args) => {
  if (!instance) instance = new WarehouseClass(args);

  return instance;
};
