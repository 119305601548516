import React, { useEffect, useState } from "react";
import { responsiveHeightSubtraction } from "../utils";
import {
  Page,
  SummaryHeading,
  SummaryDescription,
  ReadMoreButton,
  Routes,
  Scenes,
} from "../_common";
import { Scene } from "../_common/3d";
import { CameraPositions, ScenePositions } from "../_common/Constants";
import * as ArchitectScene from "./ArchitectScene";

export default (props) => {
  const { onMount, screenSize } = props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
  }, []);

  useEffect(() => {
    ArchitectScene.whenReady(() => {
      Scene().moveScenePosition(ScenePositions[screenSize].summary.Architect);
      Scene().moveCameraPosition(CameraPositions[screenSize].summary.Architect);
      onMount && onMount(Scenes.Architect);
    });
  }, [onMount, screenSize]);

  if (!loaded) return <></>;

  return (
    <Page
      id="architect"
      className="summary"
      footer="large"
      style={{ paddingTop: height }}
    >
      <div className="summary-content">
        <SummaryHeading main="Architect" rest="A Lasting Solution" />
        <SummaryDescription>
          Building software can be a daunting undertaking. Let us help you apply
          best practices, use appropriate tools and craft a solution to take
          your market by storm. 
        </SummaryDescription>
        <ReadMoreButton location={`${Routes.Architect}${Routes.More}`} />
      </div>
    </Page>
  );
};
