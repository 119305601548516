import React from "react";

export default (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1792 1792">
    <g transform="matrix(1,0,0,-1,364.47458,1270.2373)" id="g3027">
      <path
        d="m 768,896 q 0,106 -75,181 -75,75 -181,75 -106,0 -181,-75 -75,-75 -75,-181 0,-106 75,-181 75,-75 181,-75 106,0 181,75 75,75 75,181 z m 256,0 q 0,-109 -33,-179 L 627,-57 q -16,-33 -47.5,-52 -31.5,-19 -67.5,-19 -36,0 -67.5,19 Q 413,-90 398,-57 L 33,717 Q 0,787 0,896 q 0,212 150,362 150,150 362,150 212,0 362,-150 150,-150 150,-362 z"
        id="path3029"
        fill="currentColor"
      />
    </g>
  </svg>
);
