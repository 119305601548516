import React from "react";
import "./styles/SectionGraphic.scss";

/**
 * @param {string} main
 * @param {string} rest
 */
export default ({ className, children, transparentBackground }) => {
  let classes = ["content-section-graphic"];

  if (transparentBackground) classes.push("transparent");

  if (className) classes = classes.concat(className.split(" "));

  return <div className={classes.join(" ")}>{children}</div>;
};
