import React from "react";
import "./styles/Loader.scss";

export default (props) => {
  const { loaded, total } = props;

  return (
    <div className="loader">
      <div className="progress-bar">
        <span style={{ width: `${(loaded / (total - 1)) * 100}%` }} />
      </div>
      <p>
        Preparing your experience... {Math.round((loaded / (total - 1)) * 100)}%
      </p>
    </div>
  );
};
