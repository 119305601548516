import React, { useEffect, useState } from "react";
import { responsiveHeightSubtraction } from "../utils";
import {
  Page,
  SummaryHeading,
  SummaryDescription,
  ReadMoreButton,
  Routes,
} from "../_common";
import { Scene } from "../_common/3d";
import { ScenePositions, CameraPositions, Scenes } from "../_common/Constants";
import * as TeamScene from "./TeamScene";

export default (props) => {
  const { onMount, screenSize } = props;
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - responsiveHeightSubtraction());
    setTimeout(() => setLoaded(true), 10);
  }, []);

  useEffect(() => {
    TeamScene.whenReady(() => {
      Scene().moveScenePosition(ScenePositions[screenSize].summary.Team);
      Scene().moveCameraPosition(CameraPositions[screenSize].summary.Team);
      onMount && onMount(Scenes.Team);
    });
  }, [onMount, screenSize]);

  if (!loaded) return <></>;

  return (
    <Page
      id="team"
      className="summary"
      footer="large"
      style={{ paddingTop: height }}
    >
      <div className="summary-content">
        <SummaryHeading main="Team" rest="Up" />
        <SummaryDescription>
          Great teams produce the best software. We cover all traditional
          and emerging roles to ensure your project succeeds.
        </SummaryDescription>
        <ReadMoreButton location={`${Routes.Team}${Routes.More}`} />
      </div>
    </Page>
  );
};
