import Object3D from "./Object3D";
import * as THREE from "three";
import gsap, { Power1 } from "gsap";

class SpotLightClass extends Object3D {
  constructor(args) {
    super();

    const l = new THREE.SpotLight(
      args?.color ?? 0xffffff,
      args?.intensity ?? 0.4,
      args?.distance ?? 20,
      args?.angle ?? Math.PI / 4,
      args?.penumbra ?? 0.5,
      args?.decay ?? 1
    );

    l.position.set(
      args?.position?.x ?? 0,
      args?.position?.y ?? 0,
      args?.position?.z ?? 0
    );
    l.shadow.mapSize.width = args?.shadow?.resolution ?? 4096;
    l.shadow.mapSize.height = args?.shadow?.resolution ?? 4096;
    l.shadow.camera.near = args?.shadow?.near ?? 0.5;
    l.shadow.camera.far = args?.shadow?.far ?? 500;
    l.shadow.camera.fov = args?.shadow?.fov ?? 30;
    l.shadow.bias = -0.00001;

    if (args?.target) {
      l.target.position.set(l.target.x ?? 0, l.target.y ?? 0, l.target.z ?? 0);
      l.target.updateMatrixWorld();
    }

    this.init(l, { ...args, shadow: { cast: true, receive: false } });
    this.target = l.target;
  }

  move = () => {
    if (this.moving) return this;

    const bob = { x: this.object3d.position.x, y: this.object3d.position.y };

    this.bobTween = gsap.to(this.object3d.position, {
      x: bob.x - 0.3,
      y: bob.y - 0.1,
      duration: 3,
      repeat: -1,
      yoyo: true,
      ease: Power1.easeInOut,
    });

    this.moving = true;

    return this;
  };
}

export default ({
  key,
  color,
  intensity,
  distance,
  angle,
  penumbra,
  decay,
  position,
  shadow,
  target,
}) =>
  new SpotLightClass({
    key,
    color,
    intensity,
    distance,
    angle,
    penumbra,
    decay,
    position,
    shadow,
    target,
  });
