import Object3D from "./Object3D";
import * as THREE from "three";
import { DoubleSide, RepeatWrapping } from "three";
import { getAsset } from "../../utils/AssetLoader";

class PlaneClass extends Object3D {
  constructor(args) {
    super();

    const gridTexture = getAsset("Images.GridTexture");
    gridTexture.repeat.set(302.3, 302.3);
    gridTexture.wrapS = RepeatWrapping;
    gridTexture.wrapT = RepeatWrapping;
    gridTexture.rotation = 2.098;
    gridTexture.offset = new THREE.Vector2(0.412, 0.67);

    const gridMat = new THREE.MeshPhongMaterial({
      side: DoubleSide,
      map: gridTexture,
      transparent: true,
    });

    const grid = new THREE.Mesh(
      new THREE.PlaneGeometry(args?.width ?? 1000, args?.height ?? 1000),
      gridMat
    );
    grid.rotation.x = Math.PI * 1.5;
    grid.receiveShadow = true;

    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(args?.width ?? 1000, args?.height ?? 1000),
      new THREE.MeshStandardMaterial({
        ...args?.material,
        side: DoubleSide,
      })
    );
    plane.rotation.x = Math.PI * 1.5;
    plane.receiveShadow = true;

    const group = new THREE.Group();
    group.position.x = args?.position?.x ?? 0;
    group.position.y = args?.position?.y ?? 0;
    group.position.z = args?.position?.z ?? 0;

    group.add(plane);
    group.add(grid);

    this.init(group, { ...args, shadow: { cast: false, receive: true } });

    this.plane = plane;
    this.grid = grid;
  }

  hideGrid = () => {
    this.grid.visible = false;
  };

  showGrid = () => {
    this.grid.visible = true;
  };
}

export default (props) => new PlaneClass(props);
